import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import {
  GET_ANSWERS_BY_QUESTION_IDS,
  GET_QUESTIONS_BY_TESTPAPER_IDS,
  GET_TESTPAPER_QUESTION_ANSWER,
  GET_USER_TESTPAPER_STATUS,
} from "../../../services/ApiConstant";
import { getUserData, postRequest } from "../../../services";
import { Modal } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import Question from "../../../assets/common/Question";
import Options from "../../../assets/common/Option";

const ExamAnswer = () => {
  const [questions, setQuestions] = useState();
  const navigate = useNavigate();
  const [questionNumber, setQuestionNumber] = useState();
  const [result, setResult] = useState();
  const [ans, setAns] = useState();
  const [group, setGroup] = useState([]);
  const { confirm } = Modal;
  const [YourAns, setYourAns] = useState();
  const [timer, setTimer] = useState();
  let testPaperID = localStorage.getItem("testPaperID");
  let examID = localStorage.getItem("examID");
  let time = localStorage.getItem("ExamTime");
  let examName = localStorage.getItem("examName");
  let exam = localStorage.getItem("exam");
  const ExamYear = localStorage.getItem("yearSelected");

  useEffect(() => {
    getUserData(
      GET_QUESTIONS_BY_TESTPAPER_IDS + `?testpaper_id=${testPaperID}`,
    ).then((res) => {
      setQuestions(res.data);
    });
  }, []);

  useEffect(() => {
    getUserData(
      GET_TESTPAPER_QUESTION_ANSWER +
      `?exam_id=${examID}&testpaper_id=${testPaperID}`,
    ).then((res) => {
      setYourAns(res.data);
    });
  }, []);

  const ansChecking = (ans, question) => {
    let status = { correct_answer: false, your_answer: false };
    YourAns?.map((i) => {
      if (i.question == question) {
        status.correct_answer = i.correct_answer == ans;
        status.your_answer = i.user_answer == ans;
      }
    });
    return status;
  };

  return (
    <>
      <div className="jabm-sec">
        <div className="jabm-container">
          <div className="exQulistwap">
            {/* <h2>NOVEMBER 1989 SSCE COMMERCE OBJECTIVE</h2> {timer} */}
            {exam == "SAT" ? (
              <div>
                <h1>{examName} Test</h1> <br />{" "}
                <h4> {questions?.length} QUESTIONS </h4> <br />{" "}
              </div>
            ) : (
              <h2>
                {ExamYear} {exam} {examName}
              </h2>
            )}
            {questions !== undefined ? (
              questions?.map((i, index) => (
                <>
                  {/* {i?.question_details.question_note && <div className="exQucusupload mb-4 note-bold">
                                {i?.question_details.question_note? index+1 +"." : ""}   {i?.question_details.question_note}
                                </div>}

                                {questions[index - 1]?.question_details.question_explaination != i.question_details.question_explaination && <div className="exQucusupload mb-4">
                                    {i.question_details.question_explaination}
                                </div>} */}

                  {/* <span className="same-size-question "  >
                                    {i?.question_details.question_note ||i.diagram || i.question_details.question_explaination ? index + 1 + ". " : ""}
                                    

                                    {questions[index - 1]?.question_details.question_explaination != i.question_details.question_explaination && <span className="exQucusupload mb-4">
                                        {i.question_details.question_explaination}
                                    </span>}

                                    {i?.question_details.question_note && i.question_details.question_explaination && <br />}

                                    {i?.question_details.question_note && <span className="exQucusupload mb-4 note-bold">
                                        {i?.question_details.question_note}
                                    </span>}

                                    {i.diagram && <div className="exQucusupload mb-4">
                                    <label htmlFor="upload">
                                        <img src={i?.diagram} alt="" />
                                    </label>
                                </div>}
                                </span> */}

                  <span className="expaination-ttl">
                    {questions[index - 1]?.question_details
                      .question_explaination !=
                      i.question_details.question_explaination &&
                      i.question_details.question_explaination}
                  </span>

                  <span className="note note-bold">
                    {i?.question_details.question_note || i.diagram
                      ? index + 1 + ". "
                      : ""}
                    {i?.question_details.question_note}
                  </span>

                  <span className="daigram">
                    {i.diagram && (
                      <span className="exQucusupload mb-4">
                        <label htmlFor="upload">
                          <img src={i?.diagram} alt="" />
                        </label>
                      </span>
                    )}
                  </span>

                  <div className="exQupanel">
                    <div className="qutext">
                      <h3>
                        {" "}
                        <Question
                          question={i.question_details.question_text}
                          no={
                            i?.question_details.question_note || i.diagram
                              ? ""
                              : index + 1
                          }
                        />{" "}
                      </h3>
                    </div>
                    <div className="quoplist">
                      <ul className="cusradiolist">
                        {i.answer?.map(
                          (j, index) =>
                            j.ans !== null && (
                              <li>
                                <input
                                  type="radio"
                                  id={j.ans}
                                  name={i?.question}
                                  value={j.ans}
                                  checked={
                                    ansChecking(j.ans, i?.question).your_answer
                                  }
                                />
                                <label htmlFor={j.ans}>
                                  <Options option={j?.ans} index={index} />
                                </label>{" "}
                                &nbsp;&nbsp;
                                {!ansChecking(j.ans, i?.question)
                                  .correct_answer &&
                                  ansChecking(j.ans, i?.question)
                                    .your_answer && <CloseOutlined />}
                                {ansChecking(j.ans, i?.question)
                                  .correct_answer && <CheckOutlined />}
                              </li>
                            ),
                        )}
                      </ul>
                    </div>
                  </div>
                </>
              ))
            ) : (
              <div
                className="skeleton-loading"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  rowGap: "8px",
                  columnGap: "8px",
                }}
              >
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      height: "20px",
                      width: "calc(100% - 10px)",
                      maxWidth: "570px",
                    }}
                  ></div>
                </div>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      height: "20px",
                      width: "calc(100% - 50px)",
                      maxWidth: "232px",
                    }}
                  ></div>
                </div>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      height: "20px",
                      width: "calc(100% - 50px)",
                      maxWidth: "232px",
                    }}
                  ></div>
                </div>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      height: "20px",
                      width: "calc(100% - 50px)",
                      maxWidth: "232px",
                    }}
                  ></div>
                </div>

                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      height: "20px",
                      width: "calc(100% - 50px)",
                      maxWidth: "232px",
                    }}
                  ></div>
                </div>
              </div>
            )}
            <div className="qucomm-btn-wrap">
              <button
                className="btn"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExamAnswer;
