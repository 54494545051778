import { useEffect, useState } from "react";
import { getUserData } from "../../services";
import { GET_JAMB_EXAM_QUESTIONS, GET_USER_EXAM_DETAILS, JAMB_GET_TESTPAPER_QUESTION_ANSWERS } from "../../services/ApiConstant";
import { useNavigate } from "react-router-dom";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import Question from "../../assets/common/Question";
import Options from "../../assets/common/Option";

const JAMBAnswer = () => {
    const [subject, setSubject] = useState([]);
    const [subjectReal, setSubjectReal] = useState([]);
    const [examSubjectIds, setExamSubjectIds] = useState()
    const [allQuestion, setAllQuestion] = useState()
    const [selectedQuestion, setSelectedQuestion] = useState()
    const [displayQuestion, setDisplayQuestion] = useState()
    const [group, setGroup] = useState([])
    const [testPaper, setTestPaper] = useState(localStorage.getItem("testpaperbunch"))
    const [testPaperBunch, setTestPaperBunch] = useState([])
    const [arrayOfQuestion, setArrayOfQuestion] = useState([])
    const [arrayOfSubjectReal, setArrayOfSubjectReal] = useState([]);
    const [allPaper, setAllPaper] = useState([])
    const [arrayOfSubject, setArrayOfSubject] = useState([])
    const [answer, setAnwser] = useState()
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentSubjectIndex, setCurrentSubjectIndex] = useState(0);
    const [error, setError] = useState()
    const abcd = ["A", "B", "C", "D", "E"]
    let examID = localStorage.getItem("examID")
    let testpaperId = localStorage.getItem("selectedTestpaper")
    testpaperId = testpaperId?.split(",")
    testpaperId = testpaperId?.map(Number)
    const navigate = useNavigate()
    const [groupdata, setGroupdata] = useState(JSON.parse(localStorage.getItem("group")))

    useEffect(() => {
        getUserData(GET_USER_EXAM_DETAILS + `/?exam_id=${examID}`).then((res) => {
            setSubjectReal(res.data?.filter((i) => testpaperId.includes(i.exam_subject_id)))
            let array = []
            res.data?.filter((i) => testpaperId.includes(i.exam_subject_id))?.map((i) => {
                setCurrentSubjectIndex(0)
                !(array.includes(i.exam_subject_id)) && setArrayOfSubject(arrayOfSubject => [...arrayOfSubject, i.exam_subject_id])
                array.push(i.exam_subject_id)
            })
        });

        getUserData(JAMB_GET_TESTPAPER_QUESTION_ANSWERS + `?exam_id=${examID}&testpaper_id=${testPaper}`).then((res) => {
            setAnwser(res.data)
        });
    }, [])

    useEffect(() => {
        let yearSelected = localStorage.getItem("yearSelected")
        arrayOfSubject.length > 0 &&
            getUserData(
                GET_JAMB_EXAM_QUESTIONS +
                `?exams_subjects=${arrayOfSubject}&paper_year=${yearSelected}`
            ).then((res) => {
                setAllPaper(res.data)
                subjectReal.map((i) => {
                    res.data.map((j) => {
                        if (j.subject_name === i.subject_name) {
                            setSubject(subject => [...subject, i])
                        }
                    })
                })
            });
        setExamSubjectIds(arrayOfSubject[0])
    }, [arrayOfSubject])

    useEffect(() => {
        subject.map((i) => {
            setArrayOfSubjectReal(arrayOfSubjectReal => [...arrayOfSubjectReal, i.exam_subject_id])
        })
    }, [subject])

    console.log(allPaper)

    useEffect(() => {
        setSelectedQuestion('')
        setCurrentQuestionIndex(0)
        setCurrentSubjectIndex(arrayOfSubjectReal.indexOf(Number(examSubjectIds)))
        // setDisplayQuestion()
        setDisplayQuestion()
        let yearSelected = localStorage.getItem("yearSelected")
        // examSubjectIds !== undefined && getUserData(GET_JAMB_EXAM_QUESTIONS + `?exams_subjects=${examSubjectIds}&paper_year=${yearSelected}`).then((res) => {
        if (allPaper.length != 0) {


            let res = []
            res = allPaper.filter(i => i.subject_id == examSubjectIds)
            console.log(allPaper, res, examSubjectIds)
            if (res.length != 0) {

                setError('')
                let ans = []
                setAllQuestion(res[0])
                setArrayOfQuestion([])
                let array = []
                res[0].questions?.map((i) => {
                    if (!(array.includes(i.question))) {
                        setArrayOfQuestion(arrayOfQuestion => [...arrayOfQuestion, i.question])
                        array.push(i.question)
                    }
                })

                let testPaper = res[0].testpaper_id
                let newobj = {}
                res[0].questions?.map((i) => {
                    ans = [...ans, { question_id: i.question, answer: "", attempted: false }]
                })
                newobj[testPaper] = ans
                if (!(testPaperBunch?.includes(testPaper))) {
                    setTestPaperBunch([...testPaperBunch, testPaper])
                    setGroup([...group, newobj])
                }
            }
            else {
                setArrayOfQuestion([]);
                setAllQuestion([]);
            }
            // }).catch((err) => {
            //     setError(err.response)
            //     setArrayOfQuestion([])
            //     setAllQuestion([])
            // });
        }
    }, [examSubjectIds, allPaper])

    useEffect(() => {
        setSelectedQuestion(arrayOfQuestion[0])
    }, [arrayOfQuestion])

    useEffect(() => {
        setDisplayQuestion(allQuestion?.questions?.filter((data) => (data.question === selectedQuestion)))
    }, [selectedQuestion])

    const ansChecking = (ans, question, index) => {
        let status = { correct_answer: false, your_answer: false, checked: false }
        answer?.map((i) => {
            if (i.exam_id == examSubjectIds) {
                i.corrections_ans?.map((i) => {
                    if (i.question == question) {
                        groupdata?.map((p) => {
                            p[allQuestion.testpaper_id]?.map((j) => {
                                if (j.question_id == question) {
                                    status.checked = j.checkedID == index + ans
                                    status.your_answer = j.checkedID == index + ans
                                }
                            })
                        })
                        status.correct_answer = i.correct_answer == ans
                    }
                })
            }
        })
        return status
    }

    useEffect(() => {
        setExamSubjectIds(arrayOfSubjectReal[0]);
    }, [arrayOfSubjectReal])

    const nextSubject = () => {
        let i = null
        i = (arrayOfSubjectReal.indexOf(Number(examSubjectIds)))
        setCurrentSubjectIndex(i)
        setCurrentQuestionIndex(0)
        arrayOfSubjectReal.length - 1 > i && setExamSubjectIds(arrayOfSubjectReal[i + 1])
    }

    const nextQuestion = () => {
        if (currentQuestionIndex < arrayOfQuestion.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
        let i = null
        i = (arrayOfQuestion.indexOf(Number(selectedQuestion)))
        arrayOfQuestion.length - 1 > i && setSelectedQuestion(arrayOfQuestion[i + 1])
    }

    const backQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
        let i = null
        i = (arrayOfQuestion.indexOf(Number(selectedQuestion)))
        0 < i && setSelectedQuestion(arrayOfQuestion[i - 1])
    }

    const goToDashBorad = () => {
        localStorage.removeItem("result")
        localStorage.removeItem("takenTime")
        localStorage.removeItem("testPaperBunch")
        localStorage.removeItem("yearSelected")
        localStorage.removeItem("selectedTestpaper")
        localStorage.removeItem("ExamTime")
        localStorage.removeItem("examID")
        navigate("/exam")
    }

    const questionNumberButton = (questionNo, Index) => {
        setSelectedQuestion(questionNo)
        setCurrentQuestionIndex(Index)
    }

    const handleKeyDown = (event) => {
        // Call your function for any keyboard key press
        handleKeyPress(event.key);
      };

    const handleKeyPress = (key) => {
        // Your custom logic based on the pressed key
        // console.log(`Key pressed: ${key}`);
        if(key=="ArrowLeft"){
          backQuestion()
        }
        if(key=="ArrowRight"){
          nextQuestion()
        }
      };



    return (
        <>
            <div className="jabm-sec" tabIndex={0} onKeyDown={handleKeyDown}>
                <div className="jabm-container">
                    <div className="pt-4">
                        <div className="btnPanelWrap pb-4">
                            {subject.length !== 0 ? subject?.map((i) => (
                                <button className={
                                    i.exam_subject_id == examSubjectIds
                                        ? "btn selectedSub"
                                        : "btn"} value={i.exam_subject_id} onClick={(e) => setExamSubjectIds(e.target.value)} >{i.subject_name}</button>
                            )) :

                                <div className="skeleton-loading" style={{ display: "flex", flexWrap: "wrap", rowGap: "8px", columnGap: "8px" }} >
                                    <div style={{ height: "40px", width: "103px" }}></div>
                                    <div style={{ height: "40px", width: "103px" }}></div>
                                </div>
                            }
                        </div>
                    </div>
                    {<div className="error-msg">{error}</div>}
                    <div className="quit-main-wrap pb-4">
                        <div className="exQupanel">
                            {displayQuestion !== undefined ?
                                <>
                                    {/* {displayQuestion[0]?.diagram && <div className="exQucusupload mb-4">
                                        <label htmlFor="upload">
                                            <img src={displayQuestion[0]?.diagram} alt="" />
                                        </label>
                                    </div>}
                                    <div className="exQucusupload mb-4">
                                        {displayQuestion[0]?.question_details.question_explaination}
                                    </div>
                                    <div className="qutext">
                                        <h3>
                                            <Question question={displayQuestion[0]?.question_details?.question_text} no={currentQuestionIndex + 1} />
                                        </h3>
                                    </div> */}
                                    <span className="same-size-question"  >

                                        <span className="expaination-ttl">
                                            {displayQuestion[0]?.question_details.question_explaination}
                                        </span>

                                        <span className="note note-bold">
                                            {displayQuestion[0]?.question_details.question_note || displayQuestion[0]?.diagram ? currentQuestionIndex + 1 + "." : ""}
                                            {displayQuestion[0]?.question_details.question_note}
                                        </span>

                                        <span className="daigram">

                                            {displayQuestion[0]?.diagram && <div className="exQucusupload mb-4">
                                                <label htmlFor="upload">
                                                    <img src={displayQuestion[0]?.diagram} alt="" />
                                                </label>
                                            </div>}
                                        </span>

                                    </span>


                                    <div className="qutext">
                                        <h3 className="d-flex" style={{ alignItems: "center" }} >
                                            <Question question={displayQuestion[0]?.question_details?.question_text} no={displayQuestion[0]?.question_details.question_note || displayQuestion[0]?.diagram ? "" : currentQuestionIndex + 1} />
                                        </h3>
                                    </div>
                                    <div className="quoplist">
                                        {/* {displayQuestion[0]?.question_details.question_note && <div className="exQucusupload mb-4 note-bold">
                                            {displayQuestion[0]?.question_details.question_note}
                                        </div>} */}
                                        <ul className="cusradiolist">
                                            {displayQuestion[0]?.answer.map((j, index) => (
                                                j.ans !== null && <li>
                                                    <input type="radio" id={j.ans} name={displayQuestion[0]?.question} value={j.ans} checked={ansChecking(j.ans, displayQuestion[0]?.question, index).your_answer} />
                                                    <label htmlFor={j.ans}><Options option={j?.ans} index={index} /></label> &nbsp;&nbsp;
                                                    {!ansChecking(j.ans, displayQuestion[0]?.question, index).correct_answer && ansChecking(j.ans, displayQuestion[0]?.question, index).your_answer && <CloseOutlined />}
                                                    {ansChecking(j.ans, displayQuestion[0]?.question, index).correct_answer && <CheckOutlined />}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </> :
                                <div className="skeleton-loading" style={{ display: "flex", flexWrap: "wrap", rowGap: "8px", columnGap: "8px" }} >
                                    <div style={{ width: "100%" }}>
                                        <div style={{ height: "20px", width: "calc(100% - 10px)", maxWidth: "570px" }}></div>
                                    </div>
                                    <div style={{ width: "100%" }}>
                                        <div style={{ height: "20px", width: "calc(100% - 50px)", maxWidth: "232px" }}></div>
                                    </div>
                                    <div style={{ width: "100%" }}>
                                        <div style={{ height: "20px", width: "calc(100% - 50px)", maxWidth: "232px" }}></div>
                                    </div>
                                    <div style={{ width: "100%" }}>
                                        <div style={{ height: "20px", width: "calc(100% - 50px)", maxWidth: "232px" }}></div>
                                    </div>

                                    <div style={{ width: "100%" }}>
                                        <div style={{ height: "20px", width: "calc(100% - 50px)", maxWidth: "232px" }}></div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <><div className="quit-btn-panel pb-4">
                        <div className="back-next-side">
                            <button className="btn" onClick={() => backQuestion()} disabled={currentQuestionIndex === 0} ><img src="/images/left-arrow-icon.png" alt="" />&nbsp;Back</button>
                            <button className="btn" onClick={() => nextQuestion()} disabled={currentQuestionIndex === arrayOfQuestion.length - 1}>Next&nbsp;<img src="/images/right-arrow-icon.png" alt="" /></button>
                        </div>
                        <div className="next-subject">
                            <button className="btn" disabled={currentSubjectIndex === subject?.length - 1} onClick={() => nextSubject()} >Next Subject</button>
                        </div>
                    </div>
                        <div className="btnPanelWrap pb-4">
                            {allQuestion !== undefined ?
                                allQuestion?.questions?.map((value, index) => (<>
                                    <button className={value.question == selectedQuestion ? "btn selectedSub" : "btn"} onClick={() => questionNumberButton(value.question, index)} >{index + 1}</button>
                                </>
                                )) :
                                <div className="skeleton-loading" style={{ display: "flex", flexWrap: "wrap", rowGap: "8px", columnGap: "8px" }} >
                                    <div style={{ height: "40px", width: "50px" }}></div>
                                    <div style={{ height: "40px", width: "50px" }}></div>
                                </div>
                            }
                        </div></>
                    <div className="qucomm-btn-wrap text-center">
                        <button className="btn big-btn" onClick={() => {
                            goToDashBorad()
                        }}>Go to Dashboard</button>
                    </div>
                </div>
            </div>
        </>)
}
export default JAMBAnswer

