export const StarIcon = () => (
  <svg
    width="9"
    height="9"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.46577 0.96813C4.51907 0.844366 4.69455 0.844366 4.74785 0.96813L5.81634 3.44928C5.83857 3.5009 5.88723 3.53625 5.9432 3.54144L8.63309 3.79092C8.76727 3.80337 8.82149 3.97026 8.72026 4.05919L6.69073 5.84211C6.6485 5.8792 6.62992 5.9364 6.64227 5.99124L7.23623 8.62657C7.26585 8.75802 7.12389 8.86117 7.00802 8.79237L4.68521 7.41312C4.63688 7.38443 4.57674 7.38443 4.52841 7.41312L2.2056 8.79237C2.08973 8.86117 1.94777 8.75802 1.9774 8.62657L2.57135 5.99123C2.58371 5.9364 2.56512 5.8792 2.52289 5.84211L0.493366 4.05919C0.392129 3.97026 0.446355 3.80337 0.580532 3.79092L3.27042 3.54144C3.32639 3.53625 3.37505 3.5009 3.39728 3.44928L4.46577 0.96813Z"
      fill="#F48C06"
    />
  </svg>
);
