import React, { useEffect, useState } from "react";
import AuthCard from "../../../Components/AuthCard";
import { Button, Form, Input } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { postForgetRequest } from "../../../services";
import { PASSWORD_RESET } from "../../../services/ApiConstant";

const CreatePassword = () => {
  const [form] = Form.useForm();
  const { state } = useLocation();
  const navigate = useNavigate();

  const email = state?.id.email;
  const otp = state?.id.otp;
  const phone_number = state?.id.phone_number;

  const [password, setPassword] = useState();
  const [error, setError] = useState();

  const role = localStorage.getItem("userType");

  const onFinish = (values) => {
    let value = {};
    value.email = email;
    value.otp = otp;
    value.phone_number = phone_number;
    value.new_password = values.new_password;
    value.confirm_new_password = values.confirm;
    value.role = role;
    postForgetRequest(`/${PASSWORD_RESET}`, value)
      .then((res) => {
        localStorage.setItem("token", res?.data?.token);
        if (res?.data.user.role === 1) {
          navigate("/dashboard");
        }
        if (res?.data.user.role === 2) {
          navigate("/ambassodor/ambassodor-overview");
        }
      })
      .catch((err) => {
        setError(err?.response?.data);
      });
  };

  useEffect(() => {
    setError("");
  }, [password]);

  return [
    <AuthCard isHide={true} loginPage={false}>
      <br />
      <p className="title">Create New Password</p>

      <br />
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="New Password"
          name="new_password"
          rules={[
            { required: true, message: "Please input your Password!" },
            { type: "password", message: "Please enter a Password!" },
            () => ({
              validator(_, value) {
                setPassword(value);
                if (value && value.length < 8) {
                  return Promise.reject(
                    "Password should be equal to or more than 8 characters",
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password placeholder="Enter your new password" />
        </Form.Item>
        {<div className="error-msg">{error?.new_password}</div>}
        <br />
        <Form.Item
          label="Confirm Password"
          name="confirm"
          rules={[
            { required: true, message: "Please input your Confirm Password!" },
            { type: "password", message: "Please enter a Password!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Your password and confirm password do not match"),
                );
              },
            }),
          ]}
          dependencies={["new_password"]}
        >
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>
        <div className="error-msg">{error?.confirm_new_password}</div>
        <div className="error-msg">{error?.otp && "Otp is Required"}</div>
      </Form>

      <br />
      {/* <Link to="/dashboard/home">
        <button className="auth-btn" type="button">
          Done{" "}
        </button>
      </Link> */}

      <Button className="auth-btn" type="primary" onClick={() => form.submit()}>
        {" "}
        Done{" "}
      </Button>
    </AuthCard>,
  ];
};

export default CreatePassword;
