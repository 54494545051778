import { useEffect, useState } from "react";
import LevelExam from "./LevelExam";
import { getRequest, getUserData } from "../../services";
import { GET_BLOG_DETAILS, GET_EXAM_TYPES } from "../../services/ApiConstant";
import { Tabs } from "antd";

const OtherExam = ({ step, page }) => {
  const [showMore, setShowMore] = useState(null);
  const [examTypes, setExamTypes] = useState([]);
  const [blog, setBlog] = useState([]);
  const items = [];
  const onChange = () => { };

  const searchData = undefined;

  console.log("searchData", searchData);

  useEffect(() => {
    getUserData(GET_EXAM_TYPES).then((res) => {
      setExamTypes(res?.data);
    });
    getRequest(GET_BLOG_DETAILS).then((res) => {
      setBlog(res?.data);
    });
  }, [searchData]);

  console.log(examTypes);
  searchData == undefined
    ? examTypes?.map((item, index) => {
      items[index] = {
        label: (
          <p>
            {item?.name}{" "}
            {item.coming_soon && (
              <span className="error-msg">(coming soon)</span>
            )}
          </p>
        ),
        key: index.toString(),
        children: <LevelExam searchData={searchData} item={item} />,
        disabled: item.coming_soon,
      };
    })
    : searchData.length === 0
      ? (items[0] = {
        label: <p>(No Exam Found)</p>,
        key: "1",
        children: <LevelExam searchData={searchData} item={"noData"} />,
      })
      : examTypes?.map((item, index) => {
        if (searchData[0]?.category == item.id) {
          items[index] = {
            label: (
              <p>
                {item?.name}{" "}
                {item.coming_soon && (
                  <span className="error-msg">(coming soon)</span>
                )}
              </p>
            ),
            key: index.toString(),
            children: <LevelExam item={item} />,
            disabled: item.coming_soon,
          };
        }
      });

  // const blogHandler=(item)=>{
  //   const data=blog?.filter((currBlog)=>{
  //     return currBlog?.title==item
  //   })[0]?.external_link
  //   window.location.replace(data)
  // }

  return (
    <div
      className="home"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <div className="home-left others-exam-body">
        {/*<label htmlFor="" className="text-danger">
          3 days free trial for new users only
        </label>*/}
        <Tabs
          size="large"
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        />
      </div>

      <div className="home-right for-exam">
        <h1>Blogs</h1>
        {blog && (
          <div className="right-blog-lists">
            {blog?.map((item, index) => (
              <div className="right-blog-list-item">
                <div className="image-container">
                  <a href={item?.external_link} target="_blank">
                    <img src={item.icon} />
                  </a>
                </div>
                <div className="text-container">
                  <a href={item?.external_link} target="_blank">
                    <h4>{item.title}</h4>
                  </a>
                  {/* <BlogData details={item.details} /> */}
                  <p className="desc">
                    {showMore == index
                      ? item.details
                      : `${item.details.substring(0, 50)}`}
                    {!(showMore == index) ? (
                      <a
                        name={index}
                        href={item?.external_link}
                        target="_blank"
                        className="blog-read-more"
                      >
                        {" "}
                        Read more
                      </a>
                    ) : (
                      <a
                        name={index}
                        className="blog-read-more"
                        onClick={(e) => {
                          setShowMore(null);
                        }}
                      >
                        {" "}
                        Show less
                      </a>
                    )}
                    {/* onClick={(e) => { setShowMore(e.target.name) }} */}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default OtherExam;
