import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs } from 'antd';
import { getRequest, getUserData, postRequest } from "../../services";
import { GET_BLOG_DETAILS, GET_EXAM_TYPES, USER_EXAM } from "../../services/ApiConstant";
import { MyContext } from "../../assets/common/MyContext";

const SearchPage = ({ step, page }) => {
    const navigate = useNavigate();
    const [showMore, setShowMore] = useState(null)
    const onChange = (key) => {
    };
    const [examTypes, setExamTypes] = useState([]);
    const [blog, setBlog] = useState([])
    const items = []

    const { searchData } = useContext(MyContext);
    const { searchWord } = useContext(MyContext);


    console.log("searchData", searchData)

    useEffect(() => {
        getUserData(GET_EXAM_TYPES).then((res) => {
            setExamTypes(res?.data);
        })
        getRequest(GET_BLOG_DETAILS).then((res) => {
            setBlog(res?.data);
        })
    }, [searchData])

    console.log(searchData)

    useEffect(()=>{
        if(window.location.href.includes('/search')){
            if(searchData==undefined){
                // navigate("/dashboard/home")
                window.history.back();
            }
        }
        // if(searchData==undefined){
        //     // navigate("/dashboard/home")
        //     window.history.back();
        // }
    },[searchData])

    useEffect(()=>{
        if(searchWord==''&& searchData==undefined){
            if(window.location.href.includes('/search')){
                // navigate()
                window.history.back();

            } 
        }
        console.log('searchWord',searchWord==''&& searchData==undefined)
    },[searchWord])




    const firstTimeStartExam = (id, isUtme, logo, examName, exam, time, payment) => {
        console.log(id, isUtme, logo, examName, exam, time,)
        postRequest(USER_EXAM + "?exam_id" + "=" + id).then((res) => {
            localStorage.setItem("ExamTime", time)
            localStorage.setItem('examID', id);
            if (isUtme) {
                localStorage.setItem("Logo", logo)
                localStorage.setItem("examName", examName)
                navigate("/exam/utme-simulation")
            }

            else {
                switch (exam) {
                    case "JAMB":
                        navigate("/exam/jamb-simulation")
                        break;
                    case "IELTS":
                        navigate("/exam/ielts-simulation")
                        break;

                    default:
                        navigate("/exam/exam-simulation")
                        localStorage.setItem("exam", exam)
                        break;
                }
            }
        });

    }




    return (
        <div className="home" style={{ display: 'flex', justifyContent: 'space-between' }}>
            {searchData?.length !== 0 ? <div className="home-left others-exam-body">
                {searchData?.map((item, index) => {
                    return <div key={index} className="exam-list-col">
                        {console.log(item?.id, item?.is_utme, item?.icon, item?.full_name, item?.name, item?.total_minutes, item?.payment_status)}
                        <div className="exam" >
                            <div className="examinfo" onClick={() => item.is_subscribe && firstTimeStartExam(item?.id, item?.is_utme, item?.icon, item?.full_name, item?.name, item?.total_minutes, item?.payment_status)}>
                                <div className="image">
                                    <img src={item?.icon} />
                                </div>
                                <div className="infotext">
                                    <div className="exam-name">
                                        {/* {
                                            examYearData?.filter((currExamYear) => {
                                                return currExamYear?.exam_name == item.exam
                                            })[0]?.year
                                        } */}
                                        <p>Year {item.first_year}-{item.last_year}</p>
                                        <h2>{item.full_name}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="exambtnpanel">
                                {item.is_subscribe ? <button className="start-buy buy" onClick={() => item.is_subscribe && firstTimeStartExam(item?.id, item?.is_utme, item?.icon, item?.full_name, item?.name, item?.total_minutes, item?.payment_status)}>
                                    <img src="../images/start.svg" />
                                    <p className="start-buy" >Start </p>
                                </button> : ""}
                            </div>
                        </div>
                    </div>
                })}


            </div> :

                <div className="home-left others-exam-body">
                    <div className="no-exam-find-msg">
                        <div className="icon-container">
                            <img src="/images/empty-exam.png"></img>
                        </div>
                        <div className="msg-container">
                            <h4>No Exam Found</h4>
                            {/* <p>You don't have an active subscription to an exam click the register exam button to register for one</p> */}
                            {/* <div className="action">
              <button type="button" className="register-btn" onClick={() => navigate("/dashboard/other-exams")}>Register Exam</button>
            </div> */}
                        </div>
                    </div>
                </div>

            }

            <div className="home-right for-exam">
                <h1>Blogs</h1>
                {blog && <div className="right-blog-lists">
                    {blog?.map((item, index) => (
                        <div className="right-blog-list-item">
                            <div className="image-container">
                                <a href={item?.external_link} target="_blank">
                                    <img src={item.icon} />
                                </a>
                            </div>
                            <div className="text-container">
                                <a href={item?.external_link} target="_blank">
                                    <h4>{item.title}</h4>
                                </a>
                                <p className="desc">
                                    {showMore == index ? item.details : `${item.details.substring(0, 50)}`}
                                    {!(showMore == index) ? <a name={index} href={item?.external_link} target="_blank" className="blog-read-more"> Read more</a> : <a name={index} className="blog-read-more" onClick={(e) => { setShowMore(null) }}> Show less</a>}
                                </p>
                            </div>
                        </div>
                    ))
                    }
                </div>
                }

            </div>
        </div>
    );
};

export default SearchPage;