import { Button, Form, Input, Radio, Typography, message } from 'antd';
import { useEffect, useState } from 'react';
import { Card } from 'antd';
import { getAuthRequest, postRequest } from '../../services';
import { POST_CONSTACT_US, PROFILE_API } from '../../services/ApiConstant';

const AmbassodorContact = () => {
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState('horizontal');
    const [messageApi, contextHolder] = message.useMessage()
    const [fullData, setFullData] = useState();
    const[defaultName,setDefaultName]=useState()
    const[defaultEmail,setDefaultEmail]=useState()

    const onFormLayoutChange = ({ layout }) => {
        setFormLayout(layout);
    };
    
    const onsubmit=(e)=>{
        e.type=2
        postRequest(POST_CONSTACT_US,e).then((res)=>{
            info() 
            form.resetFields(['message']);
        })
    }

    const info = (msg) => {
        messageApi.open({
            type: 'success',
            content: 'Thank you for contacting us',
        });
    };

    const getData = () => {
        getAuthRequest(PROFILE_API, {})
          .then((res) => {
            setFullData(res.data);
          })
          .catch((e) => {
          });
      };
      useEffect(() => {
        getData();
      }, []);

      useEffect(() => {
        if (fullData) {
          form.setFieldsValue({
            name: fullData?.full_name,
            email: fullData?.user?.email,
          });
        }
      }, [fullData, form]);

    return (
        <div className='contactUs-sec'>
            <div className='contactUs-wrapper'>
            <div className="left-div">
                {contextHolder}
                <Card>

                    <Typography.Title level={3} >
                        How Can We Help?
                    </Typography.Title>
                    <Typography.Title level={5}>
                        Let us know your thought
                    </Typography.Title>
                        <Form
                            layout="vertical"
                            form={form}
                            initialValues={{ layout: formLayout,name:defaultName,email:defaultEmail }}
                            onValuesChange={onFormLayoutChange}
                            onFinish={(e)=>{onsubmit(e)}}
                        >
                            <Form.Item label="Name" name='name' 
                            rules={[{required:true , message: "Please Enter Your Name" , whitespace:true }]}>
                                <Input placeholder="Full Name" disabled/>
                            </Form.Item>
                            <Form.Item label="Email" name="email"
                            rules={[{required : true , message : "Please Enter Your Email"},{type:"email",message: "The input is not valid E-mail!"}]}>
                                <Input placeholder="Your Email" disabled/>
                            </Form.Item>
                            <Form.Item label="Message" name="message" className='for-textarea'
                            rules={[{required:true, message:"Please Enter Your Message"}]} labelAlign='top'>
                                <Input.TextArea placeholder="Write Your Message"></Input.TextArea>
                            </Form.Item>
                            <div className='form-actions'>
                                <Button type="primary" onClick={()=> form.submit()}>Send Message</Button>
                            </div>
                        </Form>
                </Card>
            </div>
            <div className='right-div'>
                <img src="/images/Telephone.png" alt="telephone"/>
            </div>
            </div>
        </div>
    );
};

export default AmbassodorContact;
