import React, { useEffect, useState } from "react";
import AuthCard from "../../../Components/AuthCard";
import { Button, Modal, Select, message } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { getRequest, postAuthRequest, postRequest } from "../../../services";
import {
  EXAM_BODY,
  GET_EXAM_BODY_TYPES,
  GET_SUBJECT_BY_EXAM,
  TRANSACTION,
  USEREXAM,
} from "../../../services/ApiConstant";
import { DownOutlined } from "@ant-design/icons";
import { GET_ALL_DEPARTMENT_WISE_SUBJECTS, GET_OTHER_EXAM_DETAILS_EXAM } from "../../../services/ApiConstant";

const ExamBody = () => {
  const [messageApi, contextHolder] = message.useMessage()
  const { state } = useLocation();
  const [exam, setExam] = useState([])
  const [subject, setSubject] = useState([])
  const [maxSubject, setMaxSubject] = useState([])
  const [minSubject, setMinSubject] = useState([])
  const [selectedExam, setSelectedExam] = useState([])
  const [selectedSubject, setSelectedSubject] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [final, setFinal] = useState()
  const [error, setError] = useState()
  const [on, setOn] = useState()
  const [allExam, setAllExam] = useState()
  const [require, setRequire] = useState([])
  const [isUtme, setIsUtem] = useState(state?.id)
  const [examDetails, setExamDetails] = useState([])
  const [department, setDepartment] = useState([])
  const [fullDetails, setFullDetails] = useState([])
  const [examPrice, setExamPrice] = useState(0)
  const navigate = useNavigate();

  useEffect(() => {
    setError('')
    getRequest(EXAM_BODY + `/?category=${state?.id}`).then((res) => {
      setFullDetails(res.data)
      setExam(res?.data?.map((item) => {
        const obj = { value: item.id, label: item.name }
        return obj
      })
      )
    });
  }
    , [])

  useEffect(() => {
    getRequest(GET_SUBJECT_BY_EXAM + state?.id).then((res) => {
      setAllExam(res)
    })
  }, []);

  useEffect(() => {
    setError('')
    setExamPrice(fullDetails?.filter((i) => i.id === selectedExam)[0]?.price)
    allExam?.data.map((item) => {
      if (item.exam_id === selectedExam) {
        setMaxSubject(item.max_subject)
        setSelectedSubject([])
        setSubject(item.exam_subjects?.map((i) => {
          if (i.required) {
            setRequire(require => [...require, i.id])
          }
          const obj = { value: i.id, label: i.name, required: i.required }
          return obj
        }))
      }
    })
  }, [selectedExam]);

  const onchange = (e) => {
    setSelectedSubject(e)
  }

  const onSubmit = (e) => {
    let values = {}
    values.exams_subjects = selectedSubject.join()
    values.exam_id = selectedExam

    postRequest(USEREXAM, values).then((res) => {
      payment()
    }
    ).catch((e) => {
      setError(e.response.data.error[0])
    })
  }

  const check = (e) => {
    setError()
    if (selectedSubject.length < maxSubject) {
      if (!selectedSubject.includes(e)) {
        setSelectedSubject([...selectedSubject, e])
      }
    }
    else {
      setError(`please select only ${maxSubject} subjects `)
    }
  }

  const deleteSub = (sub) => {
    setError()
    if (require.includes(sub)) {
      setError("required subject can not be deleted ")
    }
    else {
      setSelectedSubject(selectedSubject?.filter((i) => (i !== sub)))
    }
  }

  const payment = () => {
    postAuthRequest(TRANSACTION, { exam_id: selectedExam }).then((res) => {
      setIsModalOpen(true);
      handleOk()
      res.data.message != undefined && PaymentInfo();
      window.location.href = res.data.result.data.authorization_url;
      setFinal(res)
    }
    ).catch((e) => {
    })
  }

  const PaymentInfo = () => {
    messageApi.open({
      type: 'success',
      content: 'Subscribed to plan',
    });
  };


  const handleOk = () => {
    setIsModalOpen(false);
  };

  const CustomDropdownIndicator = () => (
    <div style={{ color: '#868686' }}>
      <DownOutlined />
    </div>
  );

  useEffect(() => {
    if (selectedSubject?.length < maxSubject) {
      setError(`Please choose ${maxSubject} subjects`)
    }
  }, [error])

  const selectedDepartment = (e) => {
    setSelectedSubject([])
    setSubject(department?.filter((i) => (i.department == e))[0])

  }

  useEffect(() => {
    !isUtme && getRequest(GET_SUBJECT_BY_EXAM + state?.id.exam.mainId).then((res) => {
      setSelectedSubject([])
      setSubject(res.data?.filter((item) => (item.exam === state?.id))[0])
      setMaxSubject(res?.data.max_subject)
    })
    setError("")

    getRequest(GET_OTHER_EXAM_DETAILS_EXAM + "?exam_id=" + state?.id).then((res) => {
      setExamDetails(res.data[0])
    })

    isUtme && getRequest(GET_ALL_DEPARTMENT_WISE_SUBJECTS + "?exam_id=7").then((res) => {
      setDepartment(res.data)
    })

  }, []);

  return [
    <AuthCard
      link="/"
      linkText={""}
      subtitle=""
      page="Choose Exam Body"
      step="03"
    >
      {contextHolder}
      {/* <Modal open={isModalOpen}
        footer={[
          <Button onClick={handleOk}>
            ok
          </Button>
        ]} >
        <p>You will redirect to payment page. Your card details will be saved for now and you will be charged after 7 days.</p>

      </Modal> */}
      <br />
      <p className="subtitle">
        Which exam are you interested in Subscribing for?
      </p>
      <br />
      <Select
        suffixIcon={<CustomDropdownIndicator />}
        value={selectedExam}
        style={{ width: "100%" }}
        options={exam}
        onChange={(e) => setSelectedExam(e)}
      />
      <br />
      {
        isUtme === 4 ? <div>
          <p className="desc">Chooose Department</p>
          <br />
          <div className="select-btn-wrap">
            <Select style={{ width: '100%' }} className="select-subject" onSelect={(e) => { selectedDepartment(e) }}>
              {department?.map((i, index) => (<Select.Option value={i?.department} key={index}>{i?.department}</Select.Option>))}
            </Select>
            <br />
            <div style={{ marginTop: '20px' }}>
              <p className="desc">
                You are to select {maxSubject} other subjects in addition to English Language which
                is compulsory.
              </p>
              <Select style={{ width: '100%', marginTop: '10px' }} className="select-subject" onSelect={(e) => { check(e) }}>
                {subject?.exam_subjects?.map((i, index) => (<Select.Option value={i?.id} key={index}>{i?.subject_name}</Select.Option>))}
              </Select>
            </div>
          </div>
        </div> :
          <div style={{ width: '100%' }}>
            <p className="desc">
              You are to select {maxSubject} other subjects in addition to English Language which
              is compulsory. Thereafter, proceed to view examination instructions.
            </p>
            <Select
              style={{ width: '100%' }}
              suffixIcon={<CustomDropdownIndicator />}
              onSelect={(e) => { check(e) }}
            >
              {subject?.map((i, index) => (<Select.Option value={i?.value} key={index}>{i?.label}</Select.Option>))}
            </Select>
          </div>
      }
      <br />
      {<div className="error-msg">{error}</div>}
      <div className="subjectWrap">
        {isUtme === 4 ? selectedSubject?.map((sub, index) => (
          <div className="subject" key={index}>
            {subject.exam_subjects?.map((i) => {
              { if (i.id === sub) return i.subject_name }
            })}
            &nbsp;&nbsp;&nbsp;
            <AiOutlineClose color="#2C2C2C" onClick={() => { deleteSub(sub) }} />
          </div>
        )) : selectedSubject?.map((sub) => (
          <div className="subject">
            {subject.map((i) => {
              if (i.value === sub) { return i.label }
            })}
            &nbsp;&nbsp;&nbsp;
            <AiOutlineClose color="#2C2C2C" onClick={() => { deleteSub(sub) }} />
          </div>
        ))}
      </div>
      <br />
      <br />
      <button className="auth-btn" type="button" onClick={() => onSubmit()}>
        {" "}
        Done{" "}
      </button>
    </AuthCard>,
    <div className="footer-text">
      NOTE: We're excited to offer you a 7-day free trial. You'll have full
      access to the exam of your choice during this time, completely free of
      charge. After the trial period, your account will be automatically billed
      for the subscription fee {examPrice}. You can cancel your subscription within the
      period of this 7 days.
    </div>,
  ];
};

export default ExamBody;
