import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Components/Header";

const Layout2 = (examsimulation) => {
  return (
    <div
    // className="4554"
    // style={{ width: "100%", display: "flex", flexDirection: "column" }}
    >
      <Header exam={true} examsimulation={examsimulation} />
      <div className="real-home">
        <div className="outlet">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout2;
