import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Modal, Button } from "antd";
import { Logout, getUserData,  } from "../../services";
import {
  GET_NOTIFICATION_DETAILS,
  LOGOUT_API,
} from "../../services/ApiConstant";
import { MyContext } from "../../assets/common/MyContext";

const urls = [
  {
    id: 1,
    name: "Home",
    link: "/dashboard/home",
    icon: "../images/home.svg",
    activeIcon: "../images/home_active.svg",
  },
  {
    id: 2,
    name: "Other Exams",
    link: "/dashboard/other-exams",
    icon: "../images/other-exam.svg",
    activeIcon: "../images/book_active.svg",
  },
  {
    id: 3,
    name: "Profile Settings",
    link: "/dashboard/profile-setting",
    icon: "../images/setting-2.svg",
    activeIcon: "../images/setting_active.svg",
  },
  {
    id: 4,
    name: "Contact Us",
    link: "/dashboard/contact",
    icon: "../images/contact.svg",
    activeIcon: "../images/contact_active.svg",
  },
  {
    id: 5,
    name: "Notifications",
    link: "/dashboard/notification",
    icon: "../images/notification.svg",
    activeIcon: "../images/bell_active.svg",
  },
];

const Sidebar = ({ setIsLoggedIn }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const showModal = () => {
    setOpen(true);
  };
  const logoutModal = () => {
    const handleOk = () => {
      setLoading(true);

      Logout(LOGOUT_API, {})
        .then((res) => {
          setTimeout(() => {
            setLoading(false);
            setOpen(false);
            localStorage.removeItem("token", res?.data?.token);
            localStorage.removeItem("userType", res?.data?.id);
            setIsLoggedIn(false);
          }, 1000);
        })
        .catch((e) => { });
    };
    const handleCancel = () => {
      setOpen(false);
    };

    return (
      <>
        <Modal
          open={open}
          centered={true}
          title={
            <div className="row">
              <div>
                <img src="../images/alert.png" />
                <span
                  style={{
                    color: "#002978",
                    fontSize: 16,
                    fontWeight: 400,
                    marginLeft: 10,
                  }}
                >
                  Log Out
                </span>
              </div>
            </div>
          }
          onOk={handleOk}
          onCancel={handleCancel}
          width={300}
          footer={
            <div className="w-100">
              {/* <NavLink to="/"> */}
              <Button
                className="w-100"
                key="submit"
                type="primary"
                loading={loading}
                onClick={handleOk}
              >
                Yes, I want to logout
              </Button>
              {/* </NavLink> */}

              <br />
              <br />

              <Button className="w-100 mt-20" key="back" onClick={handleCancel}>
                No, I don’t want to logout
              </Button>
            </div>
          }
        >
          <br />
          <p>Are you sure you want to Log out?</p>
          <br />
        </Modal>
      </>
    );
  };

  const { notificationCount, setNotificationCount } = useContext(MyContext);

  useEffect(() => {
    getUserData(GET_NOTIFICATION_DETAILS).then((res) => {
      let count = 0;
      res?.data.map((i) => {
        if (!i.read_status) {
          count = count + 1;
        }
      });
      setNotificationCount(count);
    });
  }, []);

  return (
    <>
      <div className="sidebar">
        {/* <ul>
        
        </ul> */}
        {urls.map((url) => (
          <div className="sideitem" key={url.id}>
            <NavLink
              to={url.link}
              onClick={() => {
                document
                  .querySelector("body")
                  .classList.remove("active-student_dashb_header");
                document
                  .querySelector(".menu-toggle")
                  .classList.remove("active");
              }}
            >
              <div className="manu-icon">
                <img
                  src={
                    url.link === location.pathname ? url.activeIcon : url.icon
                  }
                />
                {url.name === "Notification" && notificationCount != 0 && (
                  <span>{notificationCount}</span>
                )}
              </div>
              <p>{url.name}</p>
            </NavLink>
          </div>
        ))}

        <div
          className="sideitem log-out-li"
          key={"log-out"}
          onClick={() => {
            document
              .querySelector("body")
              .classList.remove("active-student_dashb_header");
            document.querySelector(".menu-toggle").classList.remove("active");
          }}
        >
          {/* <NavLink to={'/ff'}> */}
          <a alt="" onClick={showModal}>
            <div className="manu-icon">
              <img src={"../images/logout.png"} />
            </div>
            <p>{"Logout"}</p>
          </a>
          {/* </NavLink> */}
        </div>

        {logoutModal()}
      </div>
      {/* <div className="mobile-menu">
        <ul>
          {urls.map((url)=>(

            <NavLink to={url.link}><li key={url.id}><button><img src={url.link === location.pathname ? url.activeIcon : url.icon} /></button></li></NavLink>

          ))}
          <li key={"log-out"}><button onClick={showModal}><img src={"../images/logout.png"} /></button></li>
        </ul>
      </div> */}
    </>
  );
};

export default Sidebar;
