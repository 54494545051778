import { Button, Col, Modal, Row } from 'antd';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GET_ANSWERS_BY_QUESTION_IDS, GET_QUESTIONS_BY_TESTPAPER_IDS, GET_USER_TESTPAPER_STATUS } from "../../services/ApiConstant";
import { getUserData, postRequest } from "../../services";
import Question from '../../assets/common/Question';
import Options from '../../assets/common/Option';
import Calculator from '../../assets/common/Calculator';

const SATMathQuestion = () => {

    const [questions, setQuestions] = useState();
    const navigate = useNavigate();
    const [result, setResult] = useState()
    const [group, setGroup] = useState([])
    const [timeOver, setTimeOver] = useState(false)
    const [timer, setTimer] = useState()
    const [clear, setClear] = useState(true)
    const [nav, setNav] = useState(false)
    let testPaperID = localStorage.getItem("testPaperID")
    let examID = localStorage.getItem("examID")
    let time = localStorage.getItem("ExamTime")
    let examName = localStorage.getItem("examName")
    const [isCalculator, setIsCalculator] = useState(localStorage.getItem("isCalculator") == "true")
    let exam = localStorage.getItem("exam")
    const [takenTime, setTakenTime] = useState();
    const [firstQuestion, setFirstQuestion] = useState(0)
    const [open, setOpen] = useState(false);
    const [calculator, setCalculator] = useState(false)


    useEffect(() => {
        // if(!(group.length > 0)){
        getUserData(GET_QUESTIONS_BY_TESTPAPER_IDS + `?testpaper_id=${testPaperID}`).then((res) => {
            setQuestions(res.data)
            setGroup(res?.data?.map((i) => ({ "question_id": i.question, "answer": "", "attempted": false })))
            // Timer(1)
        });
    }, [])

    const gettingAns = (e, question_id) => {
        group.map((i) => {
            if (i.question_id === question_id) {
                i.answer = e.target.value
                i.attempted = true
            }
        })
        setTimeOver(!timeOver)
    }

    const [loader, setLoader] = useState(false)

    useEffect(() => {
        setLoader(false)
    }, [])

    const getResult = () => {
        if (group.length > 0) {
            setLoader(true)
            postRequest(GET_ANSWERS_BY_QUESTION_IDS + `?testpaper_id=${testPaperID}&exam_id=${examID}`, group).then((res) => {
                setResult(res.data)
                setNav(true)
            });
        }
    }
    useEffect(() => {
        if (nav) {
            showConfirm()
        }
    }, [nav])

    const onSubmit = () => {
        localStorage.setItem("takenTime", timer)
        getResult()
    }

    function myStopFunction() {
        setClear(false);
    }

    useEffect(() => { Timer(time) }, [group])

    let x = null
    const Timer = ((time) => {
        time = time * 60
        let seconds = time % 60;
        let minutes = Math.floor((time / 60) % 60);
        let hours = Math.floor(time / 3600);
        x = setInterval(function () {
            if (seconds === 0) {
                if (minutes === 0) {
                    if (hours === 0) {
                        if (time <= hours + minutes) {
                            clearInterval(x);
                            onSubmit()
                        }
                        return;
                    }
                    hours -= 1;
                    minutes = 59;
                } else {
                    minutes -= 1;
                }
                seconds = 59;
            } else {
                seconds -= 1;
            }
            function n(n) {
                return n > 9 ? "" + n : "0" + n;
            }
            setTimer(`${n(hours)}:${n(minutes)}:${n(seconds)}`)
        }, 1000);
    })

    useEffect(() => {
        if (timer !== undefined) {
            const hours = timer.slice(0, 2)
            const minutes = timer.slice(3, 5)
            const seconds = Number(timer.slice(6, 8))
            const milliSecondsTimeWeHave = time * 60 * 1000;
            const milliSecondsTimeWeTaken = (hours * 60 * 60 + 60 * minutes + seconds) * 1000
            const fakeTime = milliSecondsTimeWeHave - milliSecondsTimeWeTaken
            let realTime = msToTime(fakeTime)
            setTakenTime(realTime);
            if (result !== undefined) {
                localStorage.setItem("result", JSON.stringify(result));
                localStorage.setItem("takenTime", takenTime);
                localStorage.setItem("AnsScreenOf", "sat-math")
                navigate("/exam/result")
            }
        }
    }, [timer, result]);

    function msToTime(duration) {
        var milliseconds = Math.floor((duration % 1000) / 100),
            seconds = Math.floor((duration / 1000) % 60),
            minutes = Math.floor((duration / (1000 * 60)) % 60),
            hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;
        return hours + " hour " + minutes + " minutes " + seconds + " seconds "
    }

    const showConfirm = () => {
        if (result !== undefined) {
            localStorage.setItem("result", JSON.stringify(result))
            navigate("/exam/sat-math-ans")
        }
    };

    const ansChecking = (ans, question) => {
        let status = false
        group.map((i) => {
            if (i.question_id == question) {
                status = i.answer == ans
            }
        })
        return status
    }

    window.location.hash = "no-back-button";
    window.onhashchange = function () {
        window.location.hash = "no-back-button";
    }

    const handleCancel = () => {
        setOpen(false);
    };

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = () => {
        onSubmit()
    };

    return (
        <>
                    {loader && <div className="site-loader"> please wait exam is submitting .....</div>}

            <div className="q-set-sec">
                <div className="q-set-top-bar">
                    <div className="q-set-top-bar-wrap">
                        <div className="set-number"><strong>{isCalculator ? 4 : 3}</strong></div>
                        <div className="cal-icon"><img src={isCalculator ? "/images/calculator-allow.png" : "/images/calculator-icon.png"} onClick={() => isCalculator && setCalculator(!calculator)} /></div>
                        <div className="set-number"><strong>{isCalculator ? 4 : 3}</strong></div>
                    </div>

                </div>
                <Calculator status={calculator} setCalculator={setCalculator} />

                <div className="q-set-timing-box justify-content-center"><img src="/images/stopwatch.svg" /> <span>{timer}</span></div>

                <div className="q-set-ttl">
                    <div className="q-set-ttl-wrap">
                        {exam == "SAT" ? <div><h1>{examName} Test - {isCalculator ? "Calculator" : "No Calculator"}</h1>   <br /> <h4> {time} MINUTES, {questions?.length} QUESTIONS </h4> <br /> </div> : <h2>NOVEMBER 1989 SSCE COMMERCE OBJECTIVE</h2>}

                        {/* <h2>Reading Test</h2>
              <p>65 MINUTES, 52 QUESTIONS</p> */}
                    </div>
                </div>
                <Modal
                    className='sure-modal'
                    open={open}
                    centered={true}
                    title={
                        <div>
                            <img src="../images/alert.png" />
                            <span
                                style={{
                                    color: "#002978",
                                    fontSize: 16,
                                    fontWeight: 400,
                                    marginLeft: 10,
                                }}
                            >
                                Are you  sure?
                            </span>
                        </div>
                    }
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={300}
                    footer={
                        <div className="w-100 btn-wrap">
                            <Button key="back" onClick={handleCancel} className='m-0'>
                                No, don’t submit
                            </Button>
                            <Button
                                key="submit"
                                type="primary"
                                onClick={handleOk}
                            >
                                Yes, submit
                            </Button>
                        </div>
                    }
                >
                    <br />
                    <p style={{ color: "rgba(38, 50, 56, 1)" }}>Are you sure you want to submit your exam? This action cannot be undone. Once you submit, you will not be able to go back and change your answers.</p>
                    <br />
                </Modal>
                <div className="q-set-desc">
                    <button className="q-set-btn">DIRECTIONS</button>
                    <p className="mt-4">Each passage or pair of passages below is followed by a number of questions. After reading each passage or pair, choose the best answer to each question based on what is stated or implied in the passage or passages and in any accompanying graphics (such as a table or graph).</p>
                </div>
                <div className="q-set-wraper pt-0">
                    <div className="q-set-text-wrap">
                        <Row gutter={{ xs: 30, sm: 30, md: 35, lg: 40 }}>
                            {
                                questions?.slice(firstQuestion, firstQuestion + 4).map((i, index) =>
                                (
                                    <Col span={12}>
                                        <div className="para-q-text">
                                            <div className="num-ttl">
                                                <h4>{firstQuestion + index + 1}</h4>
                                            </div>
                                            <p>
                                                <Question question={i.question_details.question_text} />
                                            </p>
                                            <p>{i.question_details.question_note}</p>
                                            <ul className="cusradiolist">
                                                {
                                                    i.answer.map((j, index) => (
                                                        j.ans !== null && <li>
                                                            <input type="radio" id={j.ans + i?.question} name={i?.question} value={j.ans} onChange={(e) => gettingAns(e, i?.question)} checked={ansChecking(j.ans, i?.question)} />
                                                            <label htmlFor={j.ans + i?.question}><Options option={j?.ans} index={index} /></label>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </Col>)
                                )
                            }
                        </Row>
                        <div className="q-set-btn-wraper">
                            <button className="submit-btn" onClick={() => showModal()}>submit</button>
                            <div>
                                {!(firstQuestion <= 0) && <button className="continue-btn back-btn" onClick={() => setFirstQuestion(firstQuestion - 4)}>back</button>}
                                {!(firstQuestion + 4 >= questions?.length) && <button className="continue-btn" onClick={() => setFirstQuestion(firstQuestion + 4)}>progress</button>}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default SATMathQuestion;