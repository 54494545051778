import React from "react";
import { Navigate, useNavigate } from "react-router-dom";

const PrivateRoute = ({ isLoggedIn, children }) => {
  if (isLoggedIn) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

const PrivateRoute2 = ({ isLoggedIn, children }) => {
  let role = localStorage.getItem("userType");
  const navigate = useNavigate();

  if (isLoggedIn) {
    if (role === 1) {
      navigate("/dashboard/home");
      // return <Navigate to="/dashboard/home" />;
    }
    if (role === 2) {
      navigate("/ambassodor/ambassodor-overview");
      // return <Navigate to="/ambassodor/ambassodor-overview" />;
    }
  } else {
    return children;
  }
};

export { PrivateRoute, PrivateRoute2 };
