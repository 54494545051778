import React from "react";

import { Link, useNavigate } from "react-router-dom";
import { MdKeyboardArrowLeft } from "react-icons/md";

const AuthHeader = ({ step, page, currentComponent }) => {
  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="stepWrap">

        {currentComponent === "exam-type" || currentComponent === "amb-bank-details" ?

          <div className="d-flex" onClick={() => navigate(-2)}>
            {<MdKeyboardArrowLeft size={"24px"} />}
            <div
              style={{
                fontSize: 16,
                fontWeight: 500,
                color: "#4B4B4B",
                cursor: "pointer",
              }}
            >
              {" "}
              {"Back"}
            </div>
          </div> :
          <div className="d-flex" onClick={() => navigate(-1)}>
            {<MdKeyboardArrowLeft size={"24px"} />}
            <div
              style={{
                fontSize: 16,
                fontWeight: 500,
                color: "#4B4B4B",
                cursor: "pointer",
              }}
            >
              {" "}
              {"Back"}
            </div>
          </div>}
        <div className="step-right-side">
          <div style={{ fontSize: 14, color: "#B3B3B3" }}>STEP {step}/04</div>
          <div style={{ fontSize: 16, fontWeight: 500, color: "#4B4B4B" }}>
            {page}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthHeader;
