const Code3= [
    {code: "AF", code3: "AFG"}, 
    {code: "AL", code3: "ALB"}, 
    {code: "DZ", code3: "DZA"}, 
    {code: "AS", code3: "ASM"}, 
    {code: "AD", code3: "AND"}, 
    {code: "AO", code3: "AGO"}, 
    {code: "AI", code3: "AIA"}, 
    {code: "AQ", code3: "ATA"}, 
    {code: "AG", code3: "ATG"}, 
    {code: "AR", code3: "ARG"}, 
    {code: "AM", code3: "ARM"}, 
    {code: "AW", code3: "ABW"}, 
    {code: "AU", code3: "AUS"}, 
    {code: "AT", code3: "AUT"}, 
    {code: "AZ", code3: "AZE"}, 
    {code: "BS", code3: "BHS"}, 
    {code: "BH", code3: "BHR"}, 
    {code: "BD", code3: "BGD"}, 
    {code: "BB", code3: "BRB"}, 
    {code: "BY", code3: "BLR"}, 
    {code: "BE", code3: "BEL"}, 
    {code: "BZ", code3: "BLZ"}, 
    {code: "BJ", code3: "BEN"}, 
    {code: "BM", code3: "BMU"}, 
    {code: "BT", code3: "BTN"}, 
    {code: "BO", code3: "BOL"}, 
    {code: "BQ", code3: "BES"}, 
    {code: "BA", code3: "BIH"}, 
    {code: "BW", code3: "BWA"}, 
    {code: "BV", code3: "BVT"}, 
    {code: "BR", code3: "BRA"}, 
    {code: "IO", code3: "IOT"}, 
    {code: "BN", code3: "BRN"}, 
    {code: "BG", code3: "BGR"}, 
    {code: "BF", code3: "BFA"}, 
    {code: "BI", code3: "BDI"}, 
    {code: "CV", code3: "CPV"}, 
    {code: "KH", code3: "KHM"}, 
    {code: "CM", code3: "CMR"}, 
    {code: "CA", code3: "CAN"}, 
    {code: "KY", code3: "CYM"}, 
    {code: "CF", code3: "CAF"}, 
    {code: "TD", code3: "TCD"}, 
    {code: "CL", code3: "CHL"}, 
    {code: "CN", code3: "CHN"}, 
    {code: "CX", code3: "CXR"}, 
    {code: "CC", code3: "CCK"}, 
    {code: "CO", code3: "COL"}, 
    {code: "KM", code3: "COM"}, 
    {code: "CD", code3: "COD"}, 
    {code: "CG", code3: "COG"}, 
    {code: "CK", code3: "COK"}, 
    {code: "CR", code3: "CRI"}, 
    {code: "HR", code3: "HRV"}, 
    {code: "CU", code3: "CUB"}, 
    {code: "CW", code3: "CUW"}, 
    {code: "CY", code3: "CYP"}, 
    {code: "CZ", code3: "CZE"}, 
    {code: "CI", code3: "CIV"}, 
    {code: "DK", code3: "DNK"}, 
    {code: "DJ", code3: "DJI"}, 
    {code: "DM", code3: "DMA"}, 
    {code: "DO", code3: "DOM"}, 
    {code: "EC", code3: "ECU"}, 
    {code: "EG", code3: "EGY"}, 
    {code: "SV", code3: "SLV"}, 
    {code: "GQ", code3: "GNQ"}, 
    {code: "ER", code3: "ERI"}, 
    {code: "EE", code3: "EST"}, 
    {code: "SZ", code3: "SWZ"}, 
    {code: "ET", code3: "ETH"}, 
    {code: "FK", code3: "FLK"}, 
    {code: "FO", code3: "FRO"}, 
    {code: "FJ", code3: "FJI"}, 
    {code: "FI", code3: "FIN"}, 
    {code: "FR", code3: "FRA"}, 
    {code: "GF", code3: "GUF"}, 
    {code: "PF", code3: "PYF"}, 
    {code: "TF", code3: "ATF"}, 
    {code: "GA", code3: "GAB"}, 
    {code: "GM", code3: "GMB"}, 
    {code: "GE", code3: "GEO"}, 
    {code: "DE", code3: "DEU"}, 
    {code: "GH", code3: "GHA"}, 
    {code: "GI", code3: "GIB"}, 
    {code: "GR", code3: "GRC"}, 
    {code: "GL", code3: "GRL"}, 
    {code: "GD", code3: "GRD"}, 
    {code: "GP", code3: "GLP"}, 
    {code: "GU", code3: "GUM"}, 
    {code: "GT", code3: "GTM"}, 
    {code: "GG", code3: "GGY"}, 
    {code: "GN", code3: "GIN"}, 
    {code: "GW", code3: "GNB"}, 
    {code: "GY", code3: "GUY"}, 
    {code: "HT", code3: "HTI"}, 
    {code: "HM", code3: "HMD"}, 
    {code: "VA", code3: "VAT"}, 
    {code: "HN", code3: "HND"}, 
    {code: "HK", code3: "HKG"}, 
    {code: "HU", code3: "HUN"}, 
    {code: "IS", code3: "ISL"}, 
    {code: "IN", code3: "IND"}, 
    {code: "ID", code3: "IDN"}, 
    {code: "IR", code3: "IRN"}, 
    {code: "IQ", code3: "IRQ"}, 
    {code: "IE", code3: "IRL"}, 
    {code: "IM", code3: "IMN"}, 
    {code: "IL", code3: "ISR"}, 
    {code: "IT", code3: "ITA"}, 
    {code: "JM", code3: "JAM"}, 
    {code: "JP", code3: "JPN"}, 
    {code: "JE", code3: "JEY"}, 
    {code: "JO", code3: "JOR"}, 
    {code: "KZ", code3: "KAZ"}, 
    {code: "KE", code3: "KEN"}, 
    {code: "KI", code3: "KIR"}, 
    {code: "KP", code3: "PRK"}, 
    {code: "KR", code3: "KOR"}, 
    {code: "KW", code3: "KWT"}, 
    {code: "KG", code3: "KGZ"}, 
    {code: "LA", code3: "LAO"}, 
    {code: "LV", code3: "LVA"}, 
    {code: "LB", code3: "LBN"}, 
    {code: "LS", code3: "LSO"}, 
    {code: "LR", code3: "LBR"}, 
    {code: "LY", code3: "LBY"}, 
    {code: "LI", code3: "LIE"}, 
    {code: "LT", code3: "LTU"}, 
    {code: "LU", code3: "LUX"}, 
    {code: "MO", code3: "MAC"}, 
    {code: "MG", code3: "MDG"}, 
    {code: "MW", code3: "MWI"}, 
    {code: "MY", code3: "MYS"}, 
    {code: "MV", code3: "MDV"}, 
    {code: "ML", code3: "MLI"}, 
    {code: "MT", code3: "MLT"}, 
    {code: "MH", code3: "MHL"}, 
    {code: "MQ", code3: "MTQ"}, 
    {code: "MR", code3: "MRT"}, 
    {code: "MU", code3: "MUS"}, 
    {code: "YT", code3: "MYT"}, 
    {code: "MX", code3: "MEX"}, 
    {code: "FM", code3: "FSM"}, 
    {code: "MD", code3: "MDA"}, 
    {code: "MC", code3: "MCO"}, 
    {code: "MN", code3: "MNG"}, 
    {code: "ME", code3: "MNE"}, 
    {code: "MS", code3: "MSR"}, 
    {code: "MA", code3: "MAR"}, 
    {code: "MZ", code3: "MOZ"}, 
    {code: "MM", code3: "MMR"}, 
    {code: "NA", code3: "NAM"}, 
    {code: "NR", code3: "NRU"}, 
    {code: "NP", code3: "NPL"}, 
    {code: "NL", code3: "NLD"}, 
    {code: "NC", code3: "NCL"}, 
    {code: "NZ", code3: "NZL"}, 
    {code: "NI", code3: "NIC"}, 
    {code: "NE", code3: "NER"}, 
    {code: "NG", code3: "NGA"}, 
    {code: "NU", code3: "NIU"}, 
    {code: "NF", code3: "NFK"}, 
    {code: "MP", code3: "MNP"}, 
    {code: "NO", code3: "NOR"}, 
    {code: "OM", code3: "OMN"}, 
    {code: "PK", code3: "PAK"}, 
    {code: "PW", code3: "PLW"}, 
    {code: "PS", code3: "PSE"}, 
    {code: "PA", code3: "PAN"}, 
    {code: "PG", code3: "PNG"}, 
    {code: "PY", code3: "PRY"}, 
    {code: "PE", code3: "PER"}, 
    {code: "PH", code3: "PHL"}, 
    {code: "PN", code3: "PCN"}, 
    {code: "PL", code3: "POL"}, 
    {code: "PT", code3: "PRT"}, 
    {code: "PR", code3: "PRI"}, 
    {code: "QA", code3: "QAT"}, 
    {code: "MK", code3: "MKD"}, 
    {code: "RO", code3: "ROU"}, 
    {code: "RU", code3: "RUS"}, 
    {code: "RW", code3: "RWA"}, 
    {code: "RE", code3: "REU"}, 
    {code: "BL", code3: "BLM"}, 
    {code: "SH", code3: "SHN"}, 
    {code: "KN", code3: "KNA"}, 
    {code: "LC", code3: "LCA"}, 
    {code: "MF", code3: "MAF"}, 
    {code: "PM", code3: "SPM"}, 
    {code: "VC", code3: "VCT"}, 
    {code: "WS", code3: "WSM"}, 
    {code: "SM", code3: "SMR"}, 
    {code: "ST", code3: "STP"}, 
    {code: "SA", code3: "SAU"}, 
    {code: "SN", code3: "SEN"}, 
    {code: "RS", code3: "SRB"}, 
    {code: "SC", code3: "SYC"}, 
    {code: "SL", code3: "SLE"}, 
    {code: "SG", code3: "SGP"}, 
    {code: "SX", code3: "SXM"}, 
    {code: "SK", code3: "SVK"}, 
    {code: "SI", code3: "SVN"}, 
    {code: "SB", code3: "SLB"}, 
    {code: "SO", code3: "SOM"}, 
    {code: "ZA", code3: "ZAF"}, 
    {code: "GS", code3: "SGS"}, 
    {code: "SS", code3: "SSD"}, 
    {code: "ES", code3: "ESP"}, 
    {code: "LK", code3: "LKA"}, 
    {code: "SD", code3: "SDN"}, 
    {code: "SR", code3: "SUR"}, 
    {code: "SJ", code3: "SJM"}, 
    {code: "SE", code3: "SWE"}, 
    {code: "CH", code3: "CHE"}, 
    {code: "SY", code3: "SYR"}, 
    {code: "TW", code3: "TWN"}, 
    {code: "TJ", code3: "TJK"}, 
    {code: "TZ", code3: "TZA"}, 
    {code: "TH", code3: "THA"}, 
    {code: "TL", code3: "TLS"}, 
    {code: "TG", code3: "TGO"}, 
    {code: "TK", code3: "TKL"}, 
    {code: "TO", code3: "TON"}, 
    {code: "TT", code3: "TTO"}, 
    {code: "TN", code3: "TUN"}, 
    {code: "TR", code3: "TUR"}, 
    {code: "TM", code3: "TKM"}, 
    {code: "TC", code3: "TCA"}, 
    {code: "TV", code3: "TUV"}, 
    {code: "UG", code3: "UGA"}, 
    {code: "UA", code3: "UKR"}, 
    {code: "AE", code3: "ARE"}, 
    {code: "GB", code3: "GBR"}, 
    {code: "UM", code3: "UMI"}, 
    {code: "US", code3: "USA"}, 
    {code: "UY", code3: "URY"}, 
    {code: "UZ", code3: "UZB"}, 
    {code: "VU", code3: "VUT"}, 
    {code: "VE", code3: "VEN"}, 
    {code: "VN", code3: "VNM"}, 
    {code: "VG", code3: "VGB"}, 
    {code: "VI", code3: "VIR"}, 
    {code: "WF", code3: "WLF"}, 
    {code: "EH", code3: "ESH"}, 
    {code: "YE", code3: "YEM"}, 
    {code: "ZM", code3: "ZMB"}, 
    {code: "ZW", code3: "ZWE"}, 
    {code: "AX", code3: "ALA"}, 
    ]
 
const countryCode3=(code3)=>{
    return Code3.filter((i)=>i.code3==code3)[0]?.code
}  
export default countryCode3  