import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import {  IELTS_GET_ANSWER_BY_USER_TESTPAPER, IELTS_GET_QUESTION_BY_TESTPAPER_IDS } from '../../services/ApiConstant';
import { getUserData } from '../../services';
import { useNavigate } from 'react-router-dom';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Question from '../../assets/common/Question';
import Options from '../../assets/common/Option';


const IELTSANSWER = () => {

  const [allData, setAllData] = useState([])
  const [taskNo, setTaskNo] = useState(0)
  let testPaperId = localStorage.getItem("testPaperID")
  const [group, setGroup] = useState([])
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  let examID = localStorage.getItem("examID")
  let testPaper = localStorage.getItem("selectedTestpaper")
  const navigate = useNavigate()
  const [result, setResult] = useState();
  const [subjectArray, setSubjectArray] = useState([]);
  const [answer, setAnwser] = useState([])

  useEffect(() => {
    let array = []
    getUserData(IELTS_GET_QUESTION_BY_TESTPAPER_IDS + `?testpaper_id=${testPaperId}`).then((res) => {
      res.data?.map((i) => {
        i.questions?.map((i) => {
          return array.push({ "question_id": i.question, "answer": "", "attempted": false })
        })
        setAllData(res.data)
        setSubjectArray(i?.question)
        setGroup(array)
      });
    })
  }, [])

  useEffect(() => {
    getUserData(IELTS_GET_ANSWER_BY_USER_TESTPAPER + `?exam_id=${examID}&testpaper_id=${testPaperId}`).then((res) => {
      setAnwser(res.data)
    });
  }, [])

  const ansChecking = (ans, question) => {
    let status = { correct_answer: false, your_answer: false, answer: "" }

    answer[taskNo]?.corrections_ans?.map((i) => {
      if (i.question == question) {
        status.correct_answer = i.correct_answer === ans
        status.your_answer = i.user_answer === ans
        status.answer = i.user_answer
      }
    })
    return status
  }

  const nextQuestion = () => {
    setTaskNo(taskNo + 1)
    if (currentQuestionIndex < allData.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1)
    }
  }

  const previousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1)
    }
    setTaskNo(taskNo - 1)
  }

  useEffect(() => {
    if (result !== undefined) {
      navigate("/exam/ielts-check-corrections");
    }
  }, [result]);

  const questionNumberButton = (index) => {
    setTaskNo(index)
    setCurrentQuestionIndex(index)
  }

  const getAnswerStatus = (question_no) => {
    let answer_no = answer[taskNo]?.corrections_ans?.filter((i) => {
      return i?.question === question_no
    })
    return answer_no[0]?.correct_answer === answer_no[0]?.user_answer
  }

  return (
    <>

      <div className="ielts-sim-body">
        <div className="ielts-sim-header">
          <div className="sim-ttl">
            <h3>IELTS Simulator</h3>
          </div>
          <div className="sim-btn-wrap">
            <button className="btn" onClick={() => navigate('/dashboard')}>Back</button>
          </div>
        </div>
        <div className="sim-main-ttl">
          <h3>Reading Test - 2000</h3>
        </div>
        <div className="sim-main-body">
          <Row>
            <Col span={12} className='bor-solid-rt'>
              <div className="task-box " dangerouslySetInnerHTML={{ __html: allData[taskNo]?.paragraph.replace(/\n/g, "<br />") }}></div>
            </Col>
            <Col span={12}>
              <div className="task-box ds-mrl">
                <h2>Question 1 - {allData[taskNo]?.questions.length}</h2>
                <p>Do the following statments agree with the information given in the text?</p>
                <div className="bg-table mb-4">
                </div>
                <ol className="qui-list">
                  {allData[taskNo]?.questions?.map((i, index) => (
                    <li key={index} className='d-flex'>
                      <small>{index + 1}.</small>
                      <select
                        name="selectAns"
                      >
                        <option></option>
                        {i?.answer?.map((j) => {
                          return <option
                            key={j.ans}
                            value={j?.ans}
                            selected={ansChecking(j?.ans, i.question).your_answer}
                          >
                            <Options option={j?.ans}/>
                          </option>
                        })}
                      </select>
                      <span>
                      <Question question={i?.question_details?.question_text}/>  
                      </span>
                      {
                        getAnswerStatus(i?.question) ? <span style={{ marginLeft: '10px', color: 'green' }}>True</span> : <span style={{ marginLeft: '10px', color: 'red' }}>False</span>
                      }
                    </li>
                  ))}
                </ol>
                <div className="back-next-btn">
                  <button className='btn back-btn' onClick={previousQuestion} disabled={currentQuestionIndex === 0}>Back</button>
                  <button className='btn' onClick={nextQuestion} disabled={currentQuestionIndex === allData.length - 1}>Next</button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="task-menu-bar">
          {allData?.map((i, index) => (<button className={index == taskNo && 'active'} onClick={() => questionNumberButton(index)}>Task {index + 1}</button>))}
        </div>
      </div>
    </>
  );
};

export default IELTSANSWER;
