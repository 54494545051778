import { useEffect } from "react";
import { useState } from "react";
// import { marked } from 'marked';
// import MathJax from "react-mathjax-preview"
// import 'katex/dist/katex.min.css';
// import { BlockMath, InlineMath } from 'react-katex';
const MathJax = window.MathJax;

const Question = ({ question, no }) => {
  // let q = question?.replace(`xmlns=\\\"http://www.w3.org/1998/Math/MathML\\\"`, ``)
  // q = q?.replace(`xmlns=\\"http://www.w3.org/1998/Math/MathML\\"`, ``)
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(false);
    scriptLoading();
  }, [question]);

  // setTimeout(() => {
  //     scriptLoading()
  // }, 2000);

  let scriptLoading = () => {
    MathJax.typesetPromise();
  };

  setTimeout(() => {
    setLoad(true);
  }, 200);

  return (
    <>
      <div className="d-flex align-items-start">
        {no !== undefined && (
          <span className="">{no == "" ? "" : no + "."}</span>
        )}
        &nbsp;
        {/* <BlockMath math={q}/> */}
        {/* <InlineMath math={q} /> */}
        <p>
          {/*<div dangerouslySetInnerHTML={{ __html: marked.parse(question) }}/>*/}
          {question}
        </p>
        {/* <MathJax math={q}  onDisplay={()=>setLoad(true)} /> */}
        {/* {!load && <div className="skeleton-loading" style={{ display: "flex", flexWrap: "wrap", rowGap: "8px", columnGap: "8px" }}>

                    <div style={{ width: "500px" }}>
                        <div style={{ height: "20px", width: "calc(100% - 10px)", maxWidth: "570px" }}></div>
                    </div>
                </div>} */}
      </div>
    </>
  );
};
export default Question;
