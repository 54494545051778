import React, { useEffect, useState } from "react";
import { getAuthRequest, getUserData, postAuthRequest } from "../../services";
import { CANCLE_SUBSCRIPTION, GET_ALL_USER_CATEGORIES_FOR_UNSUBCRIBE, PAYMENT_CARD } from "../../services/ApiConstant";
import { Button, Modal } from "antd";
const PaymentMethod = () => {
  const [card, setCard] = useState({})
  const [showCancelSubcription, setShowCancelSubcription] = useState(false)
  const [allSubcription, setAllSubcription] = useState()
  const [open, setOpen] = useState(false);
  const [externalLink,setExternalLink] = useState()


  const getData = () => {
    getAuthRequest(PAYMENT_CARD, {})
      .then((res) => {
        setCard(res.data);
      })
      .catch((e) => {
      });

    getUserData(GET_ALL_USER_CATEGORIES_FOR_UNSUBCRIBE, {})
      .then((res) => {
        setAllSubcription(res.data)
      })
      .catch((e) => {
      });
  };

  const CancelSubcription = (id) => {
    getAuthRequest(CANCLE_SUBSCRIPTION + `?category=${id}`, {})
      .then((res) => {
        // const width = 800; // Width in pixels
        // const height = 600; // Height in pixels
        // const windowFeatures = `width=${width},height=${height},resizable=no,scrollbars,status=no`;
        // const windowName = 'Cancel Payment';
        // window.open(res.data.data.link, windowName, windowFeatures);


        // const divContainer = document.getElementById('externalLinkContainer');

        // const iframe = document.createElement('iframe');
        // iframe.src = res.data.data.link;
        // iframe.style.width = '100%';
        // iframe.style.height = '500px';
        setExternalLink(res.data.data.link)
        // setOpen(true)

        // divContainer.appendChild(iframe);

        // window.location.href = res.data.data.link;
      })
      .catch((e) => {
      });

  }

  useEffect(()=>{
    if(externalLink!==undefined){
      setOpen(true)
    }else{
      setOpen(false)
      getData()
    }
  },[externalLink])


  useEffect(() => {
    getData();
  }, []);


  const removeBtn = () => {
    // const divContainer = document.getElementById('externalLinkContainer');
    // divContainer.removeChild(divContainer.firstElementChild);
    setExternalLink()
    setOpen(false)
  }


  return (
    <div className="pro-gen-settings">
      <div className="top-heading-with-right-action">
        <h5>Manage Payment Options</h5>
        <button onClick={() => setShowCancelSubcription(true)} style={{cursor:allSubcription?.length ==0 && "no-drop"}} className="cancel-btn"> cancel subcription</button>
      </div>

      <Modal
        open={open}
        title="Cancel payment"
        onCancel={()=>removeBtn()}
        footer={[
          <Button key="back" onClick={removeBtn}>
            Return
          </Button>, 
        ]}
      >
     <div id="externalLinkContainer">
      <iframe width={"100%"} height={"500px"} src={externalLink}></iframe>
     </div>

      </Modal>

      {/* <button onClick={() => removeBtn()}>back</button>
      <div id="externalLinkContainer"></div> */}

      <div className="card-details">
        <div className="master-card">
          <img src="/images/Mastercard.svg" alt="mastercard" />
        </div>
        <div className="card_number">
          {/* {card.account_name} */}
          <p>{card.account_name}</p>
          <div>xxxx xxxx {card.last4}</div>
        </div>
        <div className="delete-icon">
          <img src="/images/Delete-Icon.png" alt="deleteicon" />
        </div>
      </div>

      {showCancelSubcription && <div>

        {allSubcription.map((Subcription) => (
          <div className="card-details">
            <div className="card_number">
              {/* {card.account_name} */}
              <p>{Subcription.category_name}</p>
            </div>
            <div className="delete-icon" onClick={() => CancelSubcription(Subcription.category)}>
              <img src="/images/Delete-Icon.png" alt="deleteicon" />
            </div>
          </div>))}
      </div>}

    </div>
  );
};

export default PaymentMethod;
