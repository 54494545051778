import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Upload,
  Switch,
  Form,
  message,
  Input,
  Button,
  Select,
  Modal,
  Space,
  Skeleton,
  InputNumber,
} from "antd";
import { MdKeyboardArrowLeft } from "react-icons/md";
import Header from "../../Components/Header";
import {
  getAuthRequest,
  Logout,
  postAuthRequest,
  postRequest,
  putAuthRequest,
} from "../../services";
import { PROFILE_API, PROFILE_UPDATE_API } from "../../services/ApiConstant";
import { country } from "../../assets/common/country";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import "react-image-crop/dist/ReactCrop.css";
import countryCode3 from "../../assets/common/countryCode3";
import { DotChartOutlined } from "@ant-design/icons";
import axios from "axios";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const GeneralSettings = ({
  step,
  page,
  fetchingUpdatingData,
  fetchingUserImage,
}) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [error, setError] = useState();
  const inputRef = useRef();

  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef();
  const imgRef = useRef();
  const hiddenAnchorRef = useRef();
  const blobUrlRef = useRef("");
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(16 / 9);
  const [userCountry, setUserCountry] = useState('')
  const [formData, setFormData] = useState({
    full_name: "",
    phone_number: "",
  });
  const [selectedCountry, setSelectedCountry] = useState("");

  const [selectedGender, setSelectedGender] = useState("");
  const [myData,setMyData]=useState()

  const [messageApi, contextHolder] = message.useMessage();

  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [fullData, setFullData] = useState();
  const [countryCallingCode, setCountryCallingCode] = useState("")
  const [phoneCode, setPhoneCode] = useState(countryCallingCode)


  const handleChange = (file) => {
    setIsModalOpen(true);
    setProfile(file.fileList[0].originFileObj);

    setCrop(undefined);
    const reader = new FileReader();
    reader.addEventListener("load", () =>
      setProfile(reader.result?.toString() || "")
    );
    reader.readAsDataURL(file.fileList[0]);
  };

  function onClickFile(e) {
    e.target.value = null
  }

  function onSelectFile(e) {
    setIsModalOpen(true);
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setProfile(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  const getData = () => {
    getAuthRequest(PROFILE_API, {})
      .then((res) => {
        setFullData(res.data);
        // selectCountryHandlerData(res.data.country)
        selectCountryHandlerCode(res.data.country)
        setStatus(true);
      })
      .catch((e) => {
      });
  };

  useEffect(() => {
    getData();
  }, [userCountry]);

  const onSubmit = (e) => {
    saveHandler();
    let formData = new FormData();

    profile !== undefined &&
      formData.append("profile_image", imgSrc, "sagar.png");
    formData.append("phone_country_code", phoneCode)

    Object.keys(e).map((k) => {
      formData.append(k, e[k]);
    });
    setLoading(true);
    // setDataForUpdate(formData)
    putAuthRequest(PROFILE_API, formData, {
      Authorization: "Token " + localStorage.getItem("token"),
    })
      .then((res) => {
        setFullData(res.data);
        successMsg()
        fetchingUserImage(res?.data?.profile_image)
        setError({});
        setLoading(false);
        // navigate("/dashboard/home");
        setPhoneCode()
      })
      .catch((e) => {
        setLoading(false);
        setError(e.response.data);
      });

  };

  const successMsg = () => {
    messageApi.open({
      type: "success",
      content: "Profile updated successfully!",
      duration: 1,
    });
    setTimeout(() => {
      navigate("/dashboard/home")
    }, 1000)

  }
  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        // let data = response.data;
        setMyData(response.data)

      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOk = () => {
    setIsModalOpen(false);
    canvasPreview(
      imgRef.current,
      previewCanvasRef.current,
      completedCrop,
      scale,
      rotate
    );

    if (!previewCanvasRef.current) {
      throw new Error("Crop canvas does not exist");
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);
      setProfile(blobUrlRef.current);
      setImgSrc(blob);
    });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setProfile(fullData?.profile_image == undefined ? require("../../assets/images/default-user-icon.png") : fullData?.profile_image)
    setImgSrc('');
    setCrop('')
    setCompletedCrop()
  };

  const saveHandler = () => {
    fetchingUpdatingData(formData, selectedCountry, selectedGender);
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const selectCountryHandler = (value) => {
    console.log(value)
    let callingCode = country.find((currCountry) => currCountry.name === value)?.callingCode
    setCountryCallingCode(callingCode)
    setPhoneCode(callingCode)
    setSelectedCountry(country.find((currCountry) => currCountry.name === value)?.code);
  };

  const selectCountryHandlerCode = (value) => {
    console.log(value)
    let callingCode = country.find((currCountry) => currCountry.code === value)?.callingCode
    setCountryCallingCode(callingCode)
    setPhoneCode(callingCode)
    setSelectedCountry(country.find((currCountry) => currCountry.name === value)?.code);
  };

  const selectGenderHandler = (value) => {
    setSelectedCountry(value);
  }; 

  useEffect(() => {
    selectCourtyFunction()
  }, [fullData,myData])


  const selectCourtyFunction = ()=>{
    navigator.geolocation.getCurrentPosition((position) => {
      setUserCountry(countryCode3(myData.country_code_iso3))
      fullData?.phone_country_code == null ? setPhoneCode(country.find((currCountry) => currCountry.code === (countryCode3(myData.country_code_iso3)))?.callingCode) : setPhoneCode(fullData.phone_country_code)
      fullData?.phone_country_code == null ? setCountryCallingCode(country.find((currCountry) => currCountry.code === (countryCode3(myData.country_code_iso3)))?.callingCode) : setPhoneCode(fullData.phone_country_code)
    })
  }

  useEffect(() => {
    form.resetFields()
  }, [userCountry])

 

  return (
    <div className="pro-gen-settings p-20 fix-the-line-hight">
      {contextHolder}
      <p
        style={{
          fontSize: 16,
          fontWeight: 500,
          paddingBottom: 15,
          color: "#242424",
        }}
      >
        Your Profile Picture
      </p>
      {status ? (
        <Form
          onFinish={(e) => onSubmit(e)}
          form={form}
          layout="vertical"
          initialValues={{
            full_name: fullData?.full_name,
            surname: fullData?.full_name.split()[1],
            email: fullData?.user?.email,
            push_notification: fullData?.push_notification,
            email_notification: fullData?.email_notification,
            // phone_country_code:fullData?.phone_country_code,
            phone_number: fullData?.phone_number,
            country: country.find((currCountry) => currCountry.code === (fullData.country == "" ? userCountry : fullData.country))?.name,
            countryCode: fullData?.countryCode,
            gender: fullData?.gender,
          }}
        >
          <div className="admin-user">
            <Modal
              title="Basic Modal"
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              maskClosable={false}
            >
              {!!profile && (
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={undefined}
                  minWidth={80}
                  minHeight={80}
                >
                  <img
                    ref={imgRef}
                    alt="Crop me"
                    src={profile}
                    style={{
                      transform: `scale(${scale}) rotate(${rotate}deg)`,
                    }}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              )}
              {!!completedCrop && (
                <>
                  <div style={{ display: "none" }}>
                    <canvas
                      ref={previewCanvasRef}
                      style={{
                        border: "1px solid black",
                        objectFit: "contain",
                        width: completedCrop.width,
                        height: completedCrop.height,
                      }}
                    />
                  </div>
                  <div></div>
                </>
              )}
            </Modal>
            <div className="user-tham">
              <img
                src={
                  profile === undefined
                    ? fullData?.profile_image === null
                      ? require("../../assets/images/default-user-icon.png")
                      : fullData?.profile_image
                    : profile
                }
                className=""
                alt=""
              />
            </div>
            <div className="upload-img-btn cusuploadbtn">
              <input
                type="file"
                id="cusUpload"
                accept="image/*"
                onChange={onSelectFile}
                onClick={onClickFile}
              />
              <label htmlFor="cusUpload">Upload Image</label>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="from-group">
                <Form.Item
                  label="Full Name"
                  name="full_name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Full Name",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    required
                    placeholder="Please enter the full name"
                    name="full_name"
                    value={formData.full_name}
                    onChange={changeHandler}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="from-group">
                <Form.Item
                  label="Country"
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Country Name",
                      whitespace: true,
                    },
                  ]}
                >
                  <Select
                    name="country"
                    onChange={selectCountryHandler}
                    value={selectedCountry}
                    showSearch={true}
                  >
                    {country.map((i) => (
                      <Select.Option value={i.name}>{i.name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {<div className="error-msg">{error?.country}</div>}
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="from-group">
                <Form.Item
                  label="Gender"
                  name="gender"
                  rules={[{ required: true, message: "Please Select Gender" }]}
                >
                  <Select
                    name="gender"
                    onChange={selectGenderHandler}
                    value={selectedGender}
                  >
                    <Select.Option value="M">Male</Select.Option>
                    <Select.Option value="F">Female</Select.Option>
                    <Select.Option value="O">Other</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="from-group">
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input disabled placeholder="Please enter your email" />
                </Form.Item>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="from-group">
                <Form.Item
                  label="Phone number"
                  name="phone_number"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Phone number",
                      whitespace: true,
                      pattern: /^[0-9]+$/,
                    },
                    {
                      max: 11,
                      message: "Phone Number should be less than or equal to 11 character",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    // onWheel={(e)=>e.preventDefault()}
                    addonBefore={
                      <div>
                        {phoneCode}
                        {/* <Select name="phone_country_code" suffixIcon={null} style={{ width: 68,border:'1px solid #D9D9D9' }} value={phoneCode?phoneCode:fullData?.phone_country_code} onSelect={(e)=>countryCode(e)}>
                          
                            <Select.Option key={country.code} value={country.code}>
                              {country.code}
                            </Select.Option>
                         
                        </Select> */}
                      </div>
                    }
                    placeholder="Please enter your phone number"
                    onChange={(e) => e.target.value <= 10 && changeHandler(e)}

                  />
                </Form.Item>
              </div>
            </div>
            <div className="col-md-6 col-sm-12"></div>
            <div className="col-md-6 col-sm-12">
              <div className="fr-panel">
                <div className="text-box">
                  <h4>Push Notifications</h4>
                  <p>
                    Push notifications are alerts that appear on a user's mobile device or web browser.
                  </p>
                </div>
                <div className="switch-box">
                  <Form.Item
                    label=""
                    valuePropName="checked"
                    name="push_notification"
                  >
                    <Switch />
                  </Form.Item>
                </div>
              </div>
              <div className="fr-panel">
                <div className="text-box">
                  <h4>Email Notifications</h4>
                  <p>
                    Email notifications are messages sent to a user's email address..
                  </p>
                </div>
                <div className="switch-box">
                  <Form.Item
                    label=""
                    valuePropName="checked"
                    name="email_notification"
                  >
                    <Switch />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="submit-actions">
              <Button
                className="auth-btn save-btn"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Save Update
              </Button>
            </div>
          </div>
        </Form>
      ) : (
        <div className="skeleton-loading">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="row">
                <div className="col-12 col-md-1" style={{marginBottom:"2%"}}>
                  <Space>
                    <Skeleton.Image active={true} />
                  </Space>
                </div>
                {/* <div className="col-1 col-md-3">
                  <div className="form-group">
                    <div className="skeleton-form-control"></div>
                  </div> */}
                {/* </div> */}
                {/* <div className="col-12 col-md-3">
                    <div className="form-group">
                      <div className="skeleton-form-control"></div>
                    </div> 
                  </div> */}
              </div>
            </div>

            <div className="col-12 col-md-12">
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="form-group">
                    <div className="skeleton-form-control"></div>
                  </div> </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <div className="skeleton-form-control"></div>
              </div> </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <div className="skeleton-form-control"></div>
              </div> </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <div className="skeleton-form-control"></div>
              </div> </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <div className="skeleton-form-control"></div>
              </div> </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <div className="skeleton-form-control"></div>
              </div> </div>

            <div className="col-12 col-md-12">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <div className="skeleton-form-control"></div>
                  </div> </div>
              </div>
            </div>
            <div className="col-12 col-md-12">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <div className="skeleton-form-control"></div>
                  </div> </div>
              </div>
            </div>
            <div className="col-12 col-md-12">
              <div className="row">
                <div className="col-4 col-md-3 ml-auto">
                  <div className="form-group">
                    <div className="skeleton-form-control"></div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      )}

    </div>
  );
};

export default GeneralSettings;
