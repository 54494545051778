export const ADD_USER_API = "/auth/signup/student/";
export const LOGIN_API = "/auth/login";
export const LOGOUT_API = "/auth/logout";
export const PROFILE_API = "/profile/";
export const USER_CATEGORY_API = "/usercatogory/";
export const GET_EXAM_TYPES = "/exam-categories/";
export const UPDATE_EXAM_TYPE = "/userexamtypestatus/";
export const GET_EXAM_BODY_TYPES = "/exam-types/";
export const GET_SUBJECT_BY_EXAM = "/getsubexamid/";
export const PASSWORD_UPDATE = "/password-update";
export const EXAM_BODY = "/exams";
export const USEREXAM = "/userexam/";
export const TRANSACTION = "/transaction/initiate/";
export const USER_DASHBOARD_REPORT = "/user_dashboard_report/";
export const GET_USER_EXAM_DETAILS = "/get_user_exam_details";
export const GET_TESTPAPER_BY_SUBJECT_ID = "/get_testpaper_by_subject_id";
export const GET_QUESTIONS_BY_TESTPAPER_IDS = "/get_questions_by_testpaper_ids";
export const GET_ANSWERS_BY_QUESTION_IDS = "/get_answers_by_question_ids";
export const GET_PERCENTAGE_EXAM_STATUS = "/get_percentage_exam_status";
export const GET_USER_TESTPAPER_STATUS = "/get_user_testpaper_status";
export const GET_TESTPAPER_QUESTION_ANSWER = "/get_testpaper_questions_answer";
export const GET_USER_EXAM_SCORE = "/get_user_exam_score";
export const GET_USER_EXAM_START_OVER = "/get_user_exam_start_over";
export const GET_PROFESSION = "/professions/";
export const GET_BANKS = "/banks/";
export const POST_BANKUPDATE = "/bankupdate/";
export const GET_BLOG_DETAILS = "/get_blog_details";
export const GET_MOTIVATION_QUOTE_DETAILS = "/get_motivation_quote_details";
export const GET_NOTIFICATION_DETAILS = "/get_notification_details";
export const GET_NOTIFICATION_READ_DETAILS = "/get_notification_read_details";
export const POST_CONSTACT_US = "/post_contact_us";
export const VERIFY_OTP = "/verify-otp";
export const REQUEST_OTP = "/request-otp";
export const GET_DISTINCT_YEAR_PAPER = "/get_distinct_year_paper";
export const GET_JAMB_EXAM_QUESTIONS = "/get_jamb_exam_questions";
export const WEEK_WORKING_HOUR = "/week_working_hour";
export const GET_RECOMMANDATION_EXAMS = "/get_recommandation_exams";
export const AMBASSADOR_ALL_REFERRALS = "/ambassador_all_referrals";
export const JAMB_GET_ANSWERS_BY_QUESTION_IDS =
  "/jamb_get_answers_by_question_ids";
export const JAMB_GET_TESTPAPER_QUESTION_ANSWERS =
  "/jamb_get_testpaper_questions_answers";
export const GET_OTHER_EXAM_DETAILS_EXAM = "/get_other_exam_details_exams";
export const PASSWORD_RESET = "password-reset";
export const GET_ALL_DEPARTMENT_WISE_SUBJECTS =
  "/get_all_department_wise_subjects";
export const RESUME_TESTPAPER_BY_TESTPAPER_IDS =
  "/resume_testpaper_by_testpaper_ids";

export const CHECK_JAMB_SUBJECTS = "/check_jamb_subjects";

export const AMBASSADOR_ALL_WITHDRAWLS = "/ambassador_all_withdrawls";
export const WITHDRAWL_REQUEST = "/withdrawl_request/";
export const PAYMENT_CARD = "/transaction/payment_card/";

export const IELTS_GET_QUESTION_BY_TESTPAPER_IDS =
  "/ielts_get_questions_by_testpaper_ids";
export const IELTS_GET_ANSWER_BY_USER_TESTPAPER =
  "/ielts_get_answer_by_user_testpaper";
export const POST_USER_CATEGORY = "/post_user_category";

export const POST_REQUEST = "/postRequest";
export const USER_EXAM = "/userexam/";

export const GET_QUESTION_IELTS_LISTENING = "/get_question_ielts_listening";

export const GET_ALL_USER_CATEGORIES_FOR_UNSUBCRIBE =
  "/get_all_user_categories_for_unsubcribe ";
export const CANCLE_SUBSCRIPTION = "/transaction/cancle_subscription/";
export const GET_SEACH_TEXT = "/get_seach_text";
export const GET_HELP_PER_EXAM = "/get_help_per_exam";
export const GET_ML_EXAM = "/get-mlexam";
export const SUBMIT_ML_EXAM = "/submit-mlpractice-exam/";
