import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserData } from "../../services";
import { GET_ALL_DEPARTMENT_WISE_SUBJECTS, GET_DISTINCT_YEAR_PAPER, GET_TESTPAPER_BY_SUBJECT_ID, GET_USER_EXAM_DETAILS } from "../../services/ApiConstant";


const UTMESimulation = () => {
    const [error, setError] = useState()
    const navigate = useNavigate();

    const proceed = () => {
        if (yearSelected !== undefined & subjectSelected.length!==0) {
            localStorage.setItem('yearSelected', yearSelected);
            localStorage.setItem("subjectSelected", subjectSelected)
            localStorage.setItem("selectedTestpaper", TestpeperIdsForYear)
            // navigate("/exam/utme-instruction")
            navigate("/exam/instruction", {
                    state: {
                        id: {examType : "UTME"}
                    }
                })
        }
        else {
            
            if(subjectSelected.length==0){
                setError("Please Select Subjects")
            }
            else if(yearSelected == undefined){
                setError("Please Select Year")
            }
            
        }
    }

    const [subjects, setSubject] = useState([])
    const [year, setYear] = useState([])
    const [yearSelected, setYearSelected] = useState([])
    const [subjectSelected, setSubjectSelected] = useState([])
    const [bunchOfTestpaper, setBunchOfTestPaper] = useState([])
    const [department,setDepartment]=useState([])
    const [selectedDep,setSelectedDep]=useState()
    // let bunchOfTestpaper = {}

    useEffect(() => {
        let examID = localStorage.getItem("examID")
        subjectSelected.length>0 && getUserData(GET_TESTPAPER_BY_SUBJECT_ID + `?exam_subjects_ids=${subjectSelected}`).then((res) => {
            setBunchOfTestPaper(res.data)
            let array = []
            let uniqueYears = [...new Set(res.data.map((i) => i.year))];
            setYear(uniqueYears);
        });
        setYearSelected([])
    }, [subjectSelected])

    useEffect(() => {
        let examID = localStorage.getItem("examID")
        setSubjectSelected([])
        setYearSelected([])

        getUserData(GET_ALL_DEPARTMENT_WISE_SUBJECTS + `?exam_id=${examID}`).then((res) => {
            setDepartment(res.data)
            if (res.data.length === 0) {
                setError("No TestPaper available for this Subject")
            }
        });
    }, [])

    useEffect(()=>{
        setSubjectSelected([])
    },[selectedDep])

    let TestpeperIdsForYear = []
    useEffect(() => {
        bunchOfTestpaper?.map((i) => {
            if (i.year == yearSelected)
                TestpeperIdsForYear.push(i.testpaper_id)
        })
    }, [yearSelected])

    const [yearSort, setYearSort] = useState([])

    useEffect(()=>{
      const sortedNumbers = [...year];
      sortedNumbers.sort((a, b) => a - b); // For ascending order
      console.log(sortedNumbers)
      setYearSort(sortedNumbers)
  
    },[year])

    return (
        <>
            <div className="jabm-sec d-flex align-items-center justify-content-center">
                <div className="jabm-wrap text-center">
                    <h1 className="quttl">Choose Subject</h1>
                    <div className="quTextboxa">
                        <p className="qupara">
                            Choose a maximum of a subject in which you want to practice for.</p>
                    </div>
                    <div className="radio-btn-wrap align-items-center justify-content-center pb-5">
                        {/* {<div className="error-msg">{error}</div>} */}

                    </div>
                    <h3 className="pb-4">SELECT DEPARTMENT</h3>    


                    <div className="radio-btn-wrap align-items-center justify-content-center pb-5">
                        {department?.map((i) =>
                        (<div className="btn-box">
                            <input type="radio" id={i.department_id} name="department" value={i.department_id} onChange={(e) => setSelectedDep(Number(e.target.value))} />
                            <label htmlFor={i.department_id}>{i.department}</label>
                        </div>)
                        )
                        }

                    </div>
                    {selectedDep!==undefined &&<><h3 className="pb-4">SELECT SUBJECT</h3>    
                    <div className="radio-btn-wrap align-items-center justify-content-center pb-5">
                        {department?.filter((i)=>i.department_id===selectedDep)[0]?.exam_subjects?.map((i,index) =>
                        (<div className="btn-box">
                            <input type="radio" id={i.subject_name} name={i.subject_name} value={i.id} checked={subjectSelected.includes(i.id)} onChange={(e) => setSubjectSelected(subjectSelected => [Number(e.target.value)])} />
                            <label htmlFor={i.subject_name} >{i.subject_name}</label>
                        </div>)
                        )
                        }
                        {/* {<div className="error-msg">{error}</div>} */}

                    </div></>}
                    
                    {subjectSelected?.length !== 0 && <>
                        <h3 className="pb-4">SELECT YEAR</h3>
                        <div className="radio-btn-wrap pb-4 justify-content-center">
                            {
                                yearSort?.map((i) => (
                                    <div className="btn-box">
                                        <input type="radio" id={i} name="year" value={i} checked={yearSelected==i} onClick={(e) => setYearSelected(e.target.value)} />
                                        <label htmlFor={i}>{i}</label>
                                    </div>
                                ))
                            }
                        </div>
                    </>}

                    {<div className="error-msg">{error}</div>}

                    { <div className="qucomm-btn-wrap" >
                        <button disabled={yearSelected.length ===0} className="btn" onClick={() => proceed()}>Proceed</button>
                    </div>}
                </div>
            </div>
        </>
    );
};

export default UTMESimulation;
