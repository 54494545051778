import { Button, Col, Row } from 'antd';
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GET_ANSWERS_BY_QUESTION_IDS, GET_QUESTIONS_BY_TESTPAPER_IDS, GET_USER_TESTPAPER_STATUS, IELTS_GET_QUESTION_BY_TESTPAPER_IDS } from "../../services/ApiConstant";
import { getUserData, postRequest } from "../../services";
import { Modal } from "antd";
import Question from '../../assets/common/Question';
import Options from '../../assets/common/Option';

const SATSimulation = () => {

  const [questions, setQuestions] = useState();
  const navigate = useNavigate();
  const [allData, setAllData] = useState([])
  const [questionNumber, setQuestionNumber] = useState();
  const [result, setResult] = useState()
  const [ans, setAns] = useState()
  const [group, setGroup] = useState([])
  const { confirm } = Modal;
  const [timeOver, setTimeOver] = useState(false)
  const [timer, setTimer] = useState()
  const [clear, setClear] = useState(true)
  const [nav, setNav] = useState(false)
  const [calculator, setCalculator] = useState(false)
  let testPaperID = localStorage.getItem("testPaperID")
  let examID = localStorage.getItem("examID")
  let time = localStorage.getItem("ExamTime")
  let examName = localStorage.getItem("examName")
  let exam = localStorage.getItem("exam")
  const [takenTime, setTakenTime] = useState();
  const abcd = ["A", "B", "C", "D", "E"]
  const [firstQuestion, setFirstQuestion] = useState(0)
  const [onScreenQuestions, setOnScreenQuestions] = useState(2)
  const [taskNo, setTaskNo] = useState(0)
  const [showPassage, setShowPassage] = useState(true)
  const [open, setOpen] = useState(false);


  useEffect(() => {
    let array = []
    getUserData(IELTS_GET_QUESTION_BY_TESTPAPER_IDS + `?testpaper_id=${testPaperID}`).then((res) => {
      res.data.map((i) => {
        i.questions.map((i) => {
          return array.push({ "question_id": i.question, "answer": "", "attempted": false })
        })
        setAllData(res.data)
        setGroup(array)
      });

    });
  }, [])


  useEffect(() => {
    if (allData[taskNo]?.questions.length <= firstQuestion) {
      setTaskNo(taskNo + 1)
      setShowPassage(true)
      setFirstQuestion(0)
    }
  }, [firstQuestion])

  const showConfirm = () => {
    if (result !== undefined) {
      localStorage.setItem("result", JSON.stringify(result))
      localStorage.setItem("takenTime", timer)
      navigate("/exam/sat-reading-ans")
    }
  };

  const ansChecking = (ans, question) => {
    let status = false
    group.map((i) => {
      if (i.question_id == question) {
        status = i.answer == ans
      }
    })
    return status
  }

  const gettingAns = (e, question_id) => {
    group.map((i) => {
      if (i.question_id === question_id) {
        i.answer = e.target.value
        i.attempted = true
      }
    })
    setTimeOver(!timeOver)
  }

  const getResult = () => {
    if (group.length > 0) {
      postRequest(GET_ANSWERS_BY_QUESTION_IDS + `?testpaper_id=${testPaperID}&exam_id=${examID}`, group).then((res) => {
        setResult(res.data)
        setNav(true)
      });
      postRequest(GET_USER_TESTPAPER_STATUS + `?testpaper_id=${testPaperID}`).then((res) => {
      });
    }
  }

  useEffect(() => {
    if (nav) {
      showConfirm()
    }
  }, [nav])

  const onSubmit = () => {
    getResult()
  }

  function myStopFunction() {
    setClear(false);
  }

  useEffect(() => { Timer(time) }, [group])

  let x = null
  const Timer = ((time) => {
    time = time * 60
    let seconds = time % 60;
    let minutes = Math.floor((time / 60) % 60);
    let hours = Math.floor(time / 3600);
    x = setInterval(function () {
      if (seconds === 0) {
        if (minutes === 0) {
          if (hours === 0) {
            if (time <= hours + minutes) {
              clearInterval(x);
              onSubmit()
            }
            return;
          }
          hours -= 1;
          minutes = 59;
        } else {
          minutes -= 1;
        }
        seconds = 59;
      } else {
        seconds -= 1;
      }
      function n(n) {
        return n > 9 ? "" + n : "0" + n;
      }
      setTimer(`${n(hours)}:${n(minutes)}:${n(seconds)}`)
    }, 1000);
  })

  useEffect(() => {
    if (timer !== undefined) {
      const hours = timer.slice(0, 2)
      const minutes = timer.slice(3, 5)
      const seconds = Number(timer.slice(6, 8))
      const milliSecondsTimeWeHave = time * 60 * 1000;
      const milliSecondsTimeWeTaken = (hours * 60 * 60 + 60 * minutes + seconds) * 1000
      const fakeTime = milliSecondsTimeWeHave - milliSecondsTimeWeTaken
      let realTime = msToTime(fakeTime)
      setTakenTime(realTime);
      if (result !== undefined) {
        localStorage.setItem("result", JSON.stringify(result));
        localStorage.setItem("takenTime", takenTime);
        localStorage.setItem("AnsScreenOf", "sat-reading")
        navigate("/exam/result")
      }
    }
  }, [timer, result]);

  function msToTime(duration) {
    var milliseconds = Math.floor((duration % 1000) / 100),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;
    return hours + " hour " + minutes + " minutes " + seconds + " seconds "
  }

  const onContinueButton = () => {
    !showPassage && setFirstQuestion(firstQuestion + 4)
    setShowPassage(false)
  }

  const onBackButton = () => {

    firstQuestion == 0 && setShowPassage(true)
    !firstQuestion == 0 && setFirstQuestion(firstQuestion - 4)
    firstQuestion == 0 & showPassage && setTaskNo(taskNo - 1)
    firstQuestion == 0 & showPassage && setFirstQuestion(allData[taskNo - 1]?.questions.length - allData[taskNo - 1]?.questions.length % 4)
    firstQuestion == 0 & showPassage && setShowPassage(false)
  }

  window.location.hash = "no-back-button";
  window.onhashchange = function () {
      window.location.hash = "no-back-button";
  }

  const handleCancel = () => {
    setOpen(false);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    onSubmit()
  };



  return (
    <>
      <div className="q-set-sec">
        <div className="q-set-top-bar">
          <div className="q-set-top-bar-wrap">
            <div className="set-number"><strong>{examName == "Writing And Language" ? 2:1}</strong></div>
            <div className="q-set-timing-box"><img src="/images/stopwatch.svg" /> <span>{timer}</span></div>
            <div className="set-number"><strong>{examName == "Writing And Language"  ? 2:1}</strong></div>
          </div>
        </div>
        <Modal
          className='sure-modal'
          open={open}
          centered={true}
          title={
            <div>
              <img src="../images/alert.png" />
              <span
                style={{
                  color: "#002978",
                  fontSize: 16,
                  fontWeight: 400,
                  marginLeft: 10,
                }}
              >
                Are you  sure?
              </span>
            </div>
          }
          onOk={handleOk}
          onCancel={handleCancel}
          width={300}
          footer={
            <div className="w-100 btn-wrap">
              <Button key="back" onClick={handleCancel}>
                No, don’t submit
              </Button>
              <Button
                key="submit"
                type="primary"
                onClick={handleOk}
              >
                Yes, submit
              </Button>
            </div>
          }
        >
          <br />
          <p style={{color:"rgba(38, 50, 56, 1)"}}>Are you sure you want to submit your exam? This action cannot be undone. Once you submit, you will not be able to go back and change your answers.</p>
          <br />
        </Modal>
        {showPassage && <>
          <div className="q-set-ttl">
            <div className="q-set-ttl-wrap">
              {exam == "SAT" ? <div><h1>{examName}Test</h1> <br /> <h4> {time} MINUTES, {group.length} QUESTIONS </h4> <br /> </div> : <h2>NOVEMBER 1989 SSCE COMMERCE OBJECTIVE</h2>}
              {/* <h2>Reading Test</h2>
              <p>65 MINUTES, 52 QUESTIONS</p> */}
            </div>
          </div>
          <div className="q-set-desc">
            <button className="q-set-btn">DIRECTIONS</button>
            <p className="mt-4">Each passage or pair of passages below is followed by a number of questions. After reading each passage or pair, choose the best answer to each question based on what is stated or implied in the passage or passages and in any accompanying graphics (such as a table or graph).</p>
          </div>
        </>}

        <div className="q-set-wraper pt-0">
          <div className="q-set-text-wrap">
            <Row gutter={{ xs: 30, sm: 30, md: 35, lg: 40 }}>

              {showPassage && <Col span={12} className='border-rt-dott'>
                <div className="q-set-text">
                  <h3>Questions 1-{allData[taskNo]?.questions.length} are based on the following passage.</h3>
                  <i>
                    <div className="task-box " dangerouslySetInnerHTML={{ __html: allData[taskNo]?.paragraph.replace(/\n/g, "<br />") }}></div>
                  </i>

                </div>
              </Col>}

              {!showPassage &&
                <>
                  {allData[taskNo]?.questions?.slice(firstQuestion, firstQuestion + 4).map((i, index) => {
                    return <Col span={12}> <div className="para-q-text">
                      <div className="num-ttl">
                        <h4>{firstQuestion + index + 1}</h4>
                      </div>
                      <p>
                      <Question question={i.question_details.question_text}/>  

                      </p>
                      <p>{i.question_details.question_note}</p>
                      {
                        i?.answer.map((j, index) => (
                          <ul className="cusradiolist">
                            <li>
                              <input type="radio" id={j.ans+i?.question} name={i?.question} value={j.ans} onChange={(e) => gettingAns(e, i?.question)} checked={ansChecking(j.ans, i?.question)} />
                              <label htmlFor={j.ans+i?.question}><Options option={j?.ans} index={index}/></label>
                            </li>
                          </ul>
                        ))
                      }
                    </div>
                    </Col>
                  })}
                </>}

            </Row>

            <div className="q-set-btn-wraper">
              <button className="submit-btn" onClick={() => showModal()}>Submit</button>
              <div>
                {!(taskNo == 0 & showPassage) && <button className="continue-btn back-btn" onClick={() => onBackButton()}>back</button>}
                {/* {!(firstQuestion + 4 != allData[taskNo]?.questions.length & taskNo >= allData.length - 1 & firstQuestion + allData[taskNo]?.questions.length%4 >= allData[taskNo]?.questions.length)&& <button className="continue-btn" onClick={() => onContinueButton()}>continue</button>} */}
                {!(allData.length == taskNo + 1 & allData[taskNo]?.questions.length <= firstQuestion + 4 & !showPassage) && <button className="continue-btn" onClick={() => onContinueButton()}>continue</button>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SATSimulation;
