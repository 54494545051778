import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


const IELTSListeningDemo = () => {
    const [play, setPlay] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.setItem("volume", 1)
    })

    const onvolumechange = (e) => {
        document.getElementById('myaudio').volume = e.target.value;
        localStorage.setItem("volume", e.target.value)
    }

    const onPlaySong = () => {
        document.getElementById('myaudio').play();
        setPlay(true)
    }
    const onPlayagain = () => {
        document.getElementById('myaudio').currentTime = 0;
        document.getElementById('myaudio').play();
        setPlay(true)
    }

    const onPause = () => {
        document.getElementById('myaudio').pause();
        setPlay(false)
    }

    const goToExamPage = () => {
        setPlay(false)
        navigate('/exam/ielts-listening-task1')
    }


    return (
        <>
            <div className="ielts-sim-header">
                <div className="sim-ttl">
                    <h3>IELTS Simulator</h3>
                </div>
            </div>
            <div className="sim-main-ttl">
                <h3>&nbsp;</h3>
            </div>
            <div className="sound-checking-sec">
                <div className="sound-checking-ttl">
                    <h3>Sound Checking</h3>
                    <div className="volume-checking-progress">
                        <p>volume control</p>
                        <input type="range" min="0" max="1" step="0.001" onChange={(e) => onvolumechange(e)} /></div>
                </div>
                <div className="sound-checking-items">
                    <Row gutter={{ xs: 30, sm: 30, md: 30, lg: 30 }}>
                        <Col span={8}>
                            <div className="sound-checking-item">
                                <audio id="myaudio" style={{ display: "none" }} src={require("../../assets/ganesh.mp3")} onEnded={() => setPlay(false)} controls></audio>

                                <div className="sound-checking-icon">

                                </div>
                                <div className="sound-checking-info">
                                    <p>Put on your headphones and click on</p>
                                    <div>

                                    </div>
                                    <span><strong>Play Sound</strong></span>
                                </div>
                                <div className="sound-checking-btn">

                                    <button className="btn" disabled={play} onClick={() => onPlaySong()}>Play</button>
                                    <button className="btn" disabled={!play} onClick={() => onPause()}>Pause</button>

                                </div>

                                {/* {volume} */}
                                <div className="volume-checking-progress"></div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="sound-checking-item">
                                <div className="sound-checking-icon">

                                </div>
                                <div className="sound-checking-info">
                                    <p>If you cannt hear the sound, change the volume on your device.</p>
                                    <span>Click <strong onClick={() => onPlayagain()}>Play Sound</strong> again</span>
                                </div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="sound-checking-item">
                                <div className="sound-checking-icon">

                                </div>
                                <div className="sound-checking-info">
                                    <p>Once you are happy with the sound, click on the start button at the bottom of the screen</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="bottom-btn">
                    <button className='btn' onClick={() => goToExamPage()}>Start</button>
                </div>
            </div>
        </>
    );
};

export default IELTSListeningDemo;
