import { useEffect, useState } from "react"

const Calculator = ({status,setCalculator})=>{
    const [screen ,setScreen]=useState("")
    // const [action , setAction] =useState()
    const[operand,setOperand]=useState()
    const [operation,setOperation]=useState()
    const[resultValue,setResultValue]=useState()
    const[currValue,setCurrValue]=useState(0)
    const[display,setDisplay]=useState()
    const[oldResultValue,setOldResultValue]=useState()

    const act = (actValue,operandValue) => {
    setOperand(operandValue)
    setOperation(actValue);
    setCurrValue(screen)
    setOldResultValue(Number(resultValue))
    setScreen('')
  };
        const result=()=>{
            if(operation==="add"){
                if(resultValue){
                setResultValue(Number(oldResultValue) + Number(screen))
                setOperation('')
            }else{
                setResultValue(Number(currValue)+Number(screen))
                setOldResultValue(Number(currValue)+Number(screen)) 
                setOperation('')
                }
            }
            if(operation==="sub"){
                if(resultValue){
                setResultValue(Number(oldResultValue) - Number(screen))
                setOperation('')
                    }else{
                        setResultValue(Number(currValue)-Number(screen))
                        setOldResultValue(Number(currValue)-Number(screen))
                setOperation('')
                    }
            }
            if(operation==="mul"){
                if(resultValue){
                setResultValue(Number(oldResultValue) * Number(screen))
                setOperation('')
                    }else{
                        setResultValue(Number(currValue)*Number(screen))
                        setOldResultValue(Number(currValue)*Number(screen))
                setOperation('')
                    }
            }
            if(operation==="div"){  
                if(resultValue){
                setResultValue(Number(oldResultValue) / Number(screen))
                setOperation('')
                    }else{
                        setResultValue(Number(currValue)/Number(screen))
                        setOldResultValue(Number(currValue)/Number(screen))

                setOperation('')
                    }
            }
        }
    
    const clr = ()=>{
        setCurrValue('')
        setScreen('')
        setDisplay('')
        setResultValue('')
        setOldResultValue('')
    }

    useEffect(()=>{
        if(resultValue){
        setDisplay(`${oldResultValue} ${operand}  ${screen} ${resultValue?'=':'0'} ${resultValue?resultValue:'0'}`)
        return;
        }
        else if(currValue){
        setDisplay(`${currValue} ${operand}  ${screen} ${resultValue?'=':''} ${resultValue?resultValue:''}`)    
        }
    },[screen,currValue,operation])

    return (
        <>
        <div className={status?"calculator active":"calculator"}>
            <div className="caloutput"><p>{ resultValue?resultValue:display?display:screen}</p></div>
            <div className="cal-btn-wrap">    
                <button onClick={()=>{setScreen(screen+"1")}} >1</button>
                <button onClick={()=>{setScreen(screen+"2")}} >2</button>
                <button onClick={()=>{setScreen(screen+"3")}} >3</button>
                <button onClick={()=>{act("add",'+')}} >+</button>
                <button onClick={()=>{setScreen(screen+"4")}} >4</button>
                <button onClick={()=>{setScreen(screen+"5")}} >5</button>
                <button onClick={()=>{setScreen(screen+"6")}} >6</button>
                <button onClick={()=>{act("sub",'-')}} >-</button>
                <button onClick={()=>{setScreen(screen+"7")}} >7</button>
                <button onClick={()=>{setScreen(screen+"8")}} >8</button>
                <button onClick={()=>{setScreen(screen+"9")}} >9</button>
                <button onClick={()=>{act("mul",'*')}} >*</button>
                <button onClick={()=>{setScreen(screen+"0")}} >0</button>
                <button onClick={()=>{setScreen(screen+".")}} >.</button>
                <button onClick={()=>{result()}} >=</button>
                <button onClick={()=>{act("div",'/')}} >/</button>
                <button className="cleartBtn" onClick={()=>{clr()}} >clr</button>
                <button onClick={()=>{setScreen(screen.slice(0,screen.length-1))}} >Back</button>
                <button style={{background:"red"}} onClick={()=>{setCalculator(false)}} >OFF</button>
            </div>
        </div>
        {status&&<div className="calculator_backdrop" onClick={()=>{setCalculator(false)}}></div>}
        </>
    )
}
export default Calculator
