import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "./Components/Header";
import Sidebar from "./Components/Sidebar";

const Layout = ({ updatedData, setIsLoggedIn, userProfileImage }) => {
  const role = localStorage.getItem("userType")
  const navigate = useNavigate()

  function CheckPath() {
    if (role === "2") {
      navigate("/ambassodor/ambassodor-overview")
    }
  }

  useEffect(() => {
    CheckPath()
  }, [role])


  return (
    <div className="site-height-wrap for-student" style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <Header updatedData={updatedData} userProfileImage={userProfileImage} />
      <div className="real-home stickyheadGap">
        <Sidebar setIsLoggedIn={setIsLoggedIn} />
        <div className="outlet">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;