import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { RiHome5Line } from "react-icons/ri";
import { Modal, Button } from "antd";
import { Logout, getAuthRequest, postRequest } from "../../services";
import { LOGOUT_API, PROFILE_API } from "../../services/ApiConstant";

const urls = [
  {
    id: 1,
    name: "Overview",
    link: "/ambassodor/ambassodor-overview",
    icon: "/images/overview-icon.svg",
    activeIcon: "/images/overview-icon.svg",
  },
  {
    id: 2,
    name: "Payments",
    link: "/ambassodor/ambassodor-payment",
    icon: "/images/payment-icon.svg",
    activeIcon: "/images/payment-icon.svg",
  },
  {
    id: 3,
    name: "Settings",
    link: "/ambassodor/ambassodor-setting",
    icon: "/images/setting-icon.svg",
    activeIcon: "/images/setting-icon.svg",
  },
  {
    id: 4,
    name: "Contact Us",
    link: "/ambassodor/ambassodor-contact",
    icon: "/images/contact-icon.svg",
    activeIcon: "/images/contact-icon.svg",
  },

];

const AmbassodorSidebar = ({ profileImage,setIsLoggedIn, setMobileMenu }) => {

  const [fullData, setFullData] = useState()

  const getData = () => {
    getAuthRequest(PROFILE_API, {}).then((res) => {
      setFullData(res.data)
    }).catch((e) => {
    })
  }
  useEffect(() => {
    getData()
  }, [])

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const showModal = ( ) => {
    setOpen(true);
  };
  const navigate= useNavigate()

  useEffect(() => {
    localStorage.setItem("profileImage", profileImage)
    const image = localStorage.getItem("profileImage")
  }, [profileImage])
  const logoutModal = () => {
    const handleOk = () => {
      setLoading(true);

      Logout(LOGOUT_API, {}).then((res) => {
        setTimeout(() => {
          setLoading(false);
          setOpen(false);
          localStorage.removeItem('token', res?.data?.token);
          localStorage.removeItem('userType', res?.data?.id)
          setIsLoggedIn(false)
        }, 1000);

      }).catch((e) => {
      })

    };
    const handleCancel = () => {
      setOpen(false);
    };

    return (

      <>
        <div className='modal-container'>
          <Modal
            open={open}
            centered={true}
            title={
              <div>
                <img src="../images/alert.png" />
                <span
                  style={{
                    color: "#002978",
                    fontSize: 16,
                    fontWeight: 400,
                    marginLeft: 10,
                  }}
                >
                  Log Out
                </span>
              </div>
            }
            onOk={handleOk}
            onCancel={handleCancel}
            width={300}
            footer={
              <div className="w-100">
                {/* <NavLink to="/"> */}
                  <Button
                    className="w-100"
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleOk}
                  >
                    Yes, I want to sign out
                  </Button>
                {/* </NavLink> */}

                <br />
                <br />

                <Button className="w-100 mt-20" key="back" onClick={handleCancel}>
                  No, I don’t want to sign out
                </Button>
              </div>
            }
          >
            <br />
            <p>Are you sure you want to Log out?</p>
            <br />
          </Modal>
        </div>
      </>
    );
  };

  return (
    <div className="ambsidebar">
      {/* <ul>
       
      </ul> */}
      <div className="sidebar-logo">
      <NavLink to={'/ambassodor/ambassodor-overview'}>

        <img className="logo" src="/images/sidebar-white-logo.svg" />
        </NavLink>

      </div>
      {urls.map((url) => (
        <div className="ambsideitem" key={url.id} onClick={() => setMobileMenu(true)}>
          <NavLink to={url.link}>
            <div className="items">
              <div className="menu-icon">
                <img src={url.link === location.pathname ? url.activeIcon : url.icon} />
              </div>
              <div className="menulabel"><p>{url.name}</p></div>
            </div>
          </NavLink>
        </div>
      ))}
      <br />
      <br />
      <br />
      <br />
      <div className="ambsideitem logout-item" key={"log-out"}>
        {/* <NavLink to={'/ff'}> */}
        <div onClick={showModal} key="Signout" className="items">
          <div className="menu-icon" >
            <img src={"/images/signout-icon.svg"} alt="icon"/>
          </div>
          <div className="menulabel"><p>{"Signout"}</p></div>
        </div>
        {/* </NavLink> */}
      </div>
      <div className="use-info">
        <div className="tham-icon">
          <img className="profile-img" src={profileImage || fullData?.profile_image || require("../../assets/images/default-user-icon.png")} alt="logo"/>
          {/* <img src={fullData?.profile_image===null?require("../../assets/images/default-user-icon.png"):profileImage===undefined? fullData?.profile_image : profileImage} /> */}
        </div>
        <div className="text">
          {/* <p>Tim Cook</p> */}
          <p>{fullData?.full_name}</p>
          <span>{fullData?.user?.email}</span>
        </div>
      </div>

      {logoutModal()}
    </div>
  );
};

export default AmbassodorSidebar;
