import { Col, Row } from 'antd';
const IELTSTASK2 = () => {
    return (
      <>
        <div className="ielts-sim-header">
          <div className="sim-ttl">
            <h3>IELTS Simulator</h3>
          </div>
          <div className="sim-time">
            <p><span>00</span>:<span>58</span></p>
          </div>
          <div className="sim-btn-wrap">
            <button className="btn">Back</button>
            <button className="btn">Submit</button>
          </div>
        </div>
        <div className="sim-main-ttl">
          <h3>Reading Test - 2017</h3>
        </div>
        <div className="sim-main-body">
          <Row>
            <Col span={12} className='bor-solid-rt'>
              <div className="task-box">
                <h3>Part time job</h3>
                <ul className='job-details-list'>
                  <li><strong>Legal Assistant</strong> - litigation</li>
                  <li><strong>Job Reference:</strong> JSVC-6012</li>
                  <li><strong>Employer Agency Name:</strong> Charterhouse Group</li>
                  <li><strong>Location:</strong>  Perth</li>
                  <li><strong>Category:</strong>  Legal</li>
                  <li><strong>Date Posted:</strong> 12/01/2011</li>
                </ul>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultricies sed sagittis ullamcorper sapien faucibus hac nibh in. Amet risus justo pharetra lacus quis. Cursus consectetur nisi donec massa quis gravida curabitur </p>
                <p>Pellentesque arcu dolor ut ut adipiscing. Nec rutrum dui adipisc</p>
                <p>Metus vestibulum diam pellentesque ac commodo.</p>
                <p>Cras magnis a massa justo. Mi tincidunt </p>
                <p>quam ac, quis</p>
              </div>
            </Col>
            <Col span={12}>
              <div className="task-box ds-mrl">
                <h2>Question 8 - 13</h2>
                <p>Do the following statments agree with the information given in the text?</p>
                <div className="bg-table mb-4">
                  <table>
                    <tbody>
                      <tr>
                        <td>TRUE</td>
                        <td>If the statement agrees with the information</td>
                      </tr>
                      <tr>
                        <td>FALSE</td>
                        <td>If the statement contradicts with the information</td>
                      </tr>
                      <tr>
                        <td>NOT GIVEN</td>
                        <td>If there is no information on this</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <ol className='qui-list'>
                  <li>
                    <small>8.</small> 
                    <select>
                      <option value=""></option>
                    </select>
                    <span>The position is well-known law firm</span>
                  </li>
                  <li>
                  <small>9.</small> 
                    <select>
                      <option value=""></option>
                    </select>
                    <span>The position is well-known law firm</span>
                  </li>
                  <li>
                  <small>10.</small> 
                    <select>
                      <option value=""></option>
                    </select>
                    <span>The position is well-known law firm</span>
                  </li>
                  <li>
                    <small>11.</small> 
                    <select>
                      <option value=""></option>
                    </select>
                    <span>The position is well-known law firm</span>
                  </li>
                  <li>
                    <small>12.</small> 
                    <select>
                      <option value=""></option>
                    </select>
                    <span>The position is well-known law firm</span>
                  </li>
                </ol>
                <div className="back-next-btn">
                  <button className='btn back-btn'>Back</button>
                  <button className='btn'>Next</button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  };
  
  export default IELTSTASK2;