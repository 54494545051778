import { useNavigate } from "react-router-dom";
const IELTSInstruction = () => {
    const navigate=useNavigate()
    const startHandler=()=>{
        navigate('/exam/ielts-Task1')
    }
    let examName = localStorage.getItem("examName")

    return (
        <>
        <div className="jabm-sec">
            <div className="jabm-container">
                <div className="instructions-sec">
                    <h2>IELTS {examName}</h2>
                    <span className="time-label">Time: 10 Minutes</span>
                    <div className="instructions-panel">
                        <h3>Instructions To Candidates</h3>
                        <ul>
                            <li>1.Answer <strong>all</strong> the questions</li>
                            <li>2.You can change your aswers at any time during the test.</li>
                            <li>3.There are 40 questions in this test</li>
                            <li>4.All questions carry one mark.</li>
                        </ul>
                        <p className="important"><strong>Important:</strong> Please do not refresh the web page during the test. if you do that, you will lose all your answers</p>
                    </div>
                    <div className="qucomm-btn-wrap">
                        <button className="btn w-100" onClick={startHandler}>Start the Test</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default IELTSInstruction;
