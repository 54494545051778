import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserData } from "../../services";
import { GET_QUESTIONS_BY_TESTPAPER_IDS } from "../../services/ApiConstant";
import Calculator from "../../assets/common/Calculator";
const SATMAthExam = () => {
  const navigate = useNavigate()
  let time = localStorage.getItem("ExamTime")
  let testPaperID = localStorage.getItem("testPaperID")
  let [calculator,setCalculator]=useState(false)
  let examName = localStorage.getItem("examName")
  let [question,setQuestions]=useState()
  const [isCalculator, setIsCalculator] = useState(localStorage.getItem("isCalculator")=="true")

  useEffect(() => {
    getUserData(GET_QUESTIONS_BY_TESTPAPER_IDS + `?testpaper_id=${testPaperID}`).then((res) => {
        setQuestions(res.data)
    });
}, [])
  
  window.location.hash = "no-back-button";
  window.onhashchange = function () {
      window.location.hash = "no-back-button";
  }
    return (
      <>
        <div className="q-set-sec">
          <div className="q-set-top-bar">
            <div className="q-set-top-bar-wrap">              
              <div className="set-number"><strong>{isCalculator?"4":"3"}</strong></div>
              <div className="cal-icon"><img src={isCalculator?"/images/calculator-allow.png":"/images/calculator-icon.png"} onClick={()=>isCalculator&&setCalculator(!calculator)} /></div>
              <div className="set-number"><strong>{isCalculator?"4":"3"}</strong></div>
            </div>
          </div>
          <Calculator status={calculator} setCalculator={setCalculator}/>
          <div className="q-set-ttl">
            <div className="q-set-ttl-wrap">
              {/* <h2>Math Test – No Calculator</h2> */}
              <h1>{examName} Test - {isCalculator? "Calculator":"No Calculator"}</h1>
              <p>{time} MINUTES, {question?.length} QUESTIONS</p>
            </div>
          </div>
          <div className="q-set-desc">
            <button className="q-set-btn">DIRECTIONS</button>
            <p className="mt-4"><strong>For questions 1-15</strong>, solve each problem, choose the best answer from the choices provided, and fill in the corresponding circle on your answer sheet. <strong>For questions 16-20</strong>, solve the problem and enter your answer in the grid on the answer sheet. Please refer to the directions before question 16 on how to enter your answers in the grid. You may use any available space in your test booklet for scratch work.</p>
          </div>
          <div className="q-set-wraper">
            <button className="q-set-btn mb-4">DIRECTIONS</button>
            <ol>
              <li>The use of a calculator is not permitted.</li>
              <li>All variables and expressions used represent real numbers unless otherwise indicated.</li>
              <li>Figures provided in this test are drawn to scale unless otherwise indicated.</li>
              <li>All figures lie in a plane unless otherwise indicated.</li>
              <li>Unless otherwise indicated, the domain of a given function f is the set of all real numbers x for which f(x) is a real number.</li>
            </ol>
            <div className="q-set-img">
              <img src="/images/math-set-img.png" />
            </div>
          </div>
          <div className="q-set-btn-wraper justify-content-end">
            <button className="continue-btn" onClick={()=>{ navigate("/exam/sat-math-question")}}>CONTINUE</button>
          </div>
        </div>
      </>
    );
  };
  
  export default SATMAthExam;