import React, { useEffect, useState } from "react";
import AuthCard from "../../../Components/AuthCard";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "antd";
import { Form, Checkbox, Button } from "antd";
import { Login } from "../../../services";
import { LOGIN_API } from "../../../services/ApiConstant";
import { convertPhoneNumber } from "../../../utils";

const AmbassodorSignin = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPass] = useState("");
  const [error, setError] = useState("");
  const [inputType, setInputType] = useState("email");

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  useEffect(() => {
    setEmail(getCookie("email"));
    setPass(getCookie("password"));
  }, []);

  const onFinish = (values) => {
    setIsLoading(true);
    if (values.phone_number) {
      values.phone_number = convertPhoneNumber(values.phone_number);
    }

    Login(LOGIN_API, values)
      .then((res) => {
        setIsLoading(false);
        if (values.remember === true) {
          document.cookie = "email=" + values.email;
          document.cookie = "password=" + values.password;
        } else {
          document.cookie =
            "email=" +
            values.email +
            "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          document.cookie =
            "password=" +
            values.password +
            "=;  Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        }
        localStorage.setItem("token", res?.data?.token);
        // localStorage.setItem('userType',res?.data?.id)
        navigate("/ambassodor/ambassodor-overview");
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e.response.data);
      });
  };
  return (
    <AuthCard
      link="/amb-create-account"
      linkText={"Create an Account"}
      subtitle="Don’t have an account? click here to"
      isHide={true}
      loginPage={true}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        {inputType === "email" && (
          <div className="input-box">
            <span
              onClick={() => setInputType("phone_number")}
              className="input-switch"
            >
              Use Phone number
            </span>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not a valid Email",
                  validateTrigger: "onBlur",
                },
                { required: true, message: "", whitespace: true },

                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!getFieldValue("email")) {
                      return Promise.reject("Please input your Email Address");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                className={error?.email && "input-box-error"}
                placeholder="Email Address"
              />
            </Form.Item>
            {<div className="error-msg">{error?.email}</div>}
          </div>
        )}
        {inputType === "phone_number" && (
          <div className="input-box">
            <span
              onClick={() => setInputType("email")}
              className="input-switch"
            >
              Use Email
            </span>
            <Form.Item
              label="Phone Number"
              name={"phone_number"}
              rules={[
                {
                  required: true,
                  message: "Please enter your phone number",
                  whitespace: true,
                },
                () => ({
                  validator(_, value) {
                    const validPhoneNumber = /^0[789][01]\d{8}$/.test(value);
                    if (!validPhoneNumber) {
                      return Promise.reject(
                        "Please enter a valid phone number",
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                className={error?.phone_number && "input-box-error"}
                placeholder="Phone Number"
              />
            </Form.Item>
            {<div className="error-msg">{error?.phone_number}</div>}
          </div>
        )}

        {<div className="error-msg">{error?.detail}</div>}
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input
            type="password"
            placeholder="Enter your password"
            defaultValue={password}
          />
        </Form.Item>
        {<div className="error-msg">{error?.detail}</div>}
        <Form.Item className="height-unset">
          <div className="row-between">
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
            <Link to="/forgot-password">
              <div className="login-form-forgot">Forgot password</div>
            </Link>
          </div>
        </Form.Item>
        <br />
        <br />
        <Button
          className="auth-btn login--btn"
          type="primary"
          onClick={() => form.submit()}
          loading={isLoading}
        >
          {" "}
          Log in{" "}
        </Button>
      </Form>
    </AuthCard>
  );
};

export default AmbassodorSignin;
