import { useEffect, useRef, useState } from "react";
import { getUserData, postRequest } from "../../services";
import { GET_ANSWERS_BY_QUESTION_IDS, GET_QUESTION_IELTS_LISTENING } from "../../services/ApiConstant";
import { Button, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import Question from "../../assets/common/Question";

const IELTSListeningTask1 = () => {
  const testpaperId = localStorage.getItem("testPaperID")
  const [currentAudio, setCurrentAudio] = useState()
  const [allData, setAllData] = useState([])
  const inputValue = useRef()
  const [taskNo, setTaskNo] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [result, setResult] = useState([])
  const [group, setGroup] = useState([])
  const [timer, setTimer] = useState();
  const [timerStatus, setTimetStatus] = useState(false);
  const [timerReset, SetTimerRest] = useState(true);
  let examID = localStorage.getItem("examID")
  let time = localStorage.getItem("ExamTime");
  const navigate = useNavigate()
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [backModalOpen, setBackModalOpen] = useState(false)
  const [inputAns, setInputAns] = useState({})
  const [takenTime, setTakenTime] = useState()

  useEffect(() => {
    if (currentAudio !== undefined) {
      document.getElementById('myaudio').volume = localStorage.getItem("volume")
      document.getElementById('myaudio').play();
    }
    else {
      document.getElementById('myaudio').pause();
    }
  }, [currentAudio])

  useEffect(() => {
    getUserData(GET_QUESTION_IELTS_LISTENING + `?testpaper_id=${testpaperId}`).then((res) => {
      let array = []
      res.data?.map((i) => {
        i.questions?.map((i) => {
          return array.push({ "question_id": i.question, "answer": "", "attempted": false })
        })
        setGroup(array)
        setAllData(res.data)
        setTimetStatus(true);
        setCurrentAudio(res.data[taskNo]?.audio)
      });
    })
  }, [])

  const questionNumberButton = (index, status) => {
    setCurrentAudio(allData[index]?.audio)
    setTaskNo(index)
    setCurrentQuestionIndex(index)
  }

  const submitExam = () => {
    postRequest(
      GET_ANSWERS_BY_QUESTION_IDS + `?testpaper_id=${testpaperId}&exam_id=${examID}`,
      group
    ).then((res) => {
      setResult(res.data);
    }).catch((err) => {
    })
  };

  const handleOk = () => {
    setIsModalOpen(false);
    submitExam();

  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const backHandleOk = () => {
    navigate(-1)
  }

  const backHandleCancel = () => {
    setBackModalOpen(false)
  }


  const gettingAns = (e, question_id) => {
    group?.map((i) => {
      if (i.question_id === question_id) {
        setInputAns(!inputAns)
        i.answer = e.target.value
        i.attempted = true
      }
    })
  }

  const ansChecking = (question) => {
    let status = ""
    group?.map((i) => {
      if (i.question_id == question) {

        status = i.answer
      }
    })
    return status
  }

  let x = null;
  const Timer = (time) => {
    time = time * 60
    let seconds = time % 60;
    let minutes = Math.floor((time / 60) % 60);
    let hours = Math.floor(time / 3600);
    x = setInterval(function () {
      if (seconds === 0) {
        if (minutes === 0) {
          if (hours === 0) {
            clearInterval(x);
            if (timerReset) {
              group.length !== 0 && submitExam();
            }
            return;
          }
          hours -= 1;
          minutes = 59;
        } else {
          minutes -= 1;
        }
        seconds = 59;
      } else {
        seconds -= 1;
      }
      function n(n) {
        return n > 9 ? "" + n : "0" + n;
      }
      setTimer(`${n(hours)}:${n(minutes)}:${n(seconds)}`);
    }, 1000);
  };

  useEffect(() => {
    timerStatus && Timer(time);
  }, [timerStatus]);


  useEffect(() => { showConfirm() }, [result])

  const showConfirm = () => {
    if (result.length !== 0) {
      localStorage.setItem("result", JSON.stringify(result))
      localStorage.setItem("AnsScreenOf", "ieltsL");
      navigate("/exam/result")
    }
  };

  function msToTime(duration) {
    var milliseconds = Math.floor((duration % 1000) / 100),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;
    return hours + " hour " + minutes + " minutes " + seconds + " seconds "
  }

  useEffect(() => {
    if (timer !== undefined) {
      const [hours, minutes, seconds] = timer.split(':')?.map(Number);
      const milliSecondsTimeWeHave = time * 60 * 1000;
      const milliSecondsTimeWeTaken = ((hours * 60 + minutes) * 60 + seconds) * 1000;
      const fakeTime = milliSecondsTimeWeHave - milliSecondsTimeWeTaken;
      const realTime = msToTime(fakeTime);
      setTakenTime(realTime);
      if (result.length !== 0) {
        localStorage.setItem("result", JSON.stringify(result));
        localStorage.setItem("takenTime", realTime);
        localStorage.setItem("AnsScreenOf", "ieltsL");
        navigate("/exam/result")
      }
    }
  }, [timer, result]);


  window.location.hash = "no-back-button";
  window.onhashchange = function () {
      window.location.hash = "no-back-button";
  }
  
  return (
    <>
      <div className="ielts-sim-header">
        <Modal
          open={backModalOpen}
          onCancel={backHandleCancel}
          width={316}
          footer={[
            <Button key="back" onClick={backHandleCancel}>
              No, I don't want
            </Button>,
            <Button key="submit" type="primary" onClick={backHandleOk}>
              Yes, I want
            </Button>,
          ]}
        >
          <div>
            <p style={{ color: "#002978" }}>
              <img src="../images/alert.png" /> Are you sure?
            </p>
            <br />
            <p className="pb-3">
              Are you sure you want to leave your exam?
            </p>
          </div>
        </Modal>
        <Modal
          open={isModalOpen}
          onCancel={handleCancel}
          width={316}
          footer={[
            <Button key="back" onClick={handleCancel}>
              No, don't submit
            </Button>,
            <Button key="submit" type="primary" onClick={handleOk}>
              Yes, Submit
            </Button>,
          ]}
        >
          <div>
            <p style={{ color: "#002978" }}>
              <img src="../images/alert.png" /> Are you sure?
            </p>
            <br />
            <p className="pb-3">
              Are you sure you want to submit your exam? This action cannot be
              undone. Once you submit, you will not be able to go back and
              change your answers.
            </p>
          </div>
        </Modal>

        <audio id="myaudio" style={{ display: "none" }} src={currentAudio} controls></audio>

        <div className="sim-ttl">
          <h3>IELTS Simulator</h3>
        </div>
        <div className="sim-time">
          {timer ? <p><span>{timer?.slice(0, 2)}</span>:<span>{timer?.slice(3, 5)}</span>:<span>{timer?.slice(6, 8)}</span></p> : <p><span>00</span>:<span>00</span>:<span>00</span></p>}
        </div>
        <div className="sim-btn-wrap">
          <button className="btn" onClick={() => setBackModalOpen(true)}>Back</button>
          <button className="btn" onClick={() => setIsModalOpen(true)}>Submit</button>
        </div>
      </div>
      <div className="sim-main-ttl">
        <h3>{localStorage.getItem("examName")} Test - {localStorage.getItem("SelectedYear")}</h3>
        {/* <p>Listen and answer question 11 - 20</p> */}
      </div>

      <div className="ielts-qu-detils">
        <h3>Question 1-{allData[taskNo]?.questions.length}</h3>
        <p>{allData[taskNo]?.paragraph}</p>
        <div className="bg-table mb-4">
        </div>

        <ol className='from-qu-list'>
          {allData[taskNo]?.questions?.map((i, index) => {
            return <li key={index} className="d-flex">
              <small  className="d-flex" style={{alignItems: "center"}}>{index + 1}. </small>
              <p><Question question={i.question_details.question_text}/>  </p> &nbsp; &nbsp;

              <input type="text" className="list-form-con" placeholder="type correct answer" value={ansChecking(i.question)} onChange={(e) => gettingAns(e, i.question)} />
            </li>
          })}
        </ol>
      </div>

      <div className="task-menu-bar">
        {allData?.map((i, index) => (<button key={index} className={index === taskNo && 'active'} onClick={() => questionNumberButton(index, index === taskNo)}>Task {index + 1}</button>))}
      </div>

    </>
  );
};

export default IELTSListeningTask1;