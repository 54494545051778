import { Form, Modal, Input, Select, Button } from "antd";
import { useState } from "react";
import { patchAuthRequest } from "../../../services";
import { convertPhoneNumber } from "../../../utils";

export const UpdateSponsorInfo = ({ openSponsor, close }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [emailValidation, setEmailValidation] = useState();

  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log("values", values);
    setIsLoading(true);
    values = {
      ...values,
      sponsor_phone: convertPhoneNumber(values.sponsor_phone),
    };
    patchAuthRequest("/update-sponsor/", values)
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          close();
        }
      })
      .catch((error) => {
        setError(error.response.data);
        setIsLoading(false);
      });
  };

  return (
    <Modal
      open={openSponsor}
      width={540}
      centered={true}
      closable={false}
      title="PROVIDE SPONSOR'S DETAILS"
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Sponsor’s Name"
          name="sponsor_name"
          rules={[
            {
              required: true,
              message: "Enter sponsor’s name",
              whitespace: true,
            },
          ]}
        >
          <Input placeholder="Enter sponsor’s name" />
        </Form.Item>
        {<div className="error-msg">{error?.sponsor_name}</div>}

        <Form.Item
          label="Sponsor’s Email"
          name="sponsor_email"
          rules={[
            {
              required: true,
              message: "Enter sponsor’s email",
              whitespace: true,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                setEmailValidation(value);
                if (!getFieldValue("sponsor_email")) {
                  return Promise.reject("Please input your sponsor’s email");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input placeholder="Enter sponsor’s email" />
        </Form.Item>
        {<div className="error-msg">{error?.sponsor_email}</div>}

        <Form.Item
          label="Sponsor's Phone Number"
          name={"sponsor_phone"}
          rules={[
            {
              required: true,
              message: "Please enter your sponsor's phone number",
              whitespace: true,
            },
            () => ({
              validator(_, value) {
                const validPhoneNumber = /^0[789][01]\d{8}$/.test(value);
                if (!validPhoneNumber) {
                  return Promise.reject("Please enter a valid phone number");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            className={error?.sponsor_phone && "input-box-error"}
            placeholder="Enter Sponsor's Phone Number"
          />
        </Form.Item>
        {<div className="error-msg">{error?.sponsor_phone}</div>}

        <Form.Item
          label="Relationship"
          name="sponsor_relationship"
          rules={[
            {
              required: true,
              message: "Enter your relationship with the student",
              whitespace: true,
            },
          ]}
        >
          <Select>
            <Select.Option value={"parent"}>Parent</Select.Option>
            <Select.Option value={"sibling"}>Sibling</Select.Option>
            <Select.Option value={"teacher"}>School Teacher</Select.Option>
            <Select.Option value={"guardian"}>Guardian</Select.Option>
          </Select>
        </Form.Item>
        {<div className="error-msg">{error?.sponsor_relationship}</div>}

        <Button
          className="auth-btn login--btn"
          type="primary"
          loading={isLoading}
          onClick={() => form.submit()}
        >
          Submit
        </Button>
      </Form>
    </Modal>
  );
};
