import { Button, Modal } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserData } from "../../services";
import { GET_USER_EXAM_DETAILS } from "../../services/ApiConstant";

export const PredictedQuestions = () => {
  const [subjects, setSubjects] = useState();
  const [selectedSubject, setSelectedSubject] = useState();
  const [confirmation, setConfirmation] = useState(false);

  const { state } = useLocation();
  const navigate = useNavigate();
  const examId = state?.id;

  useEffect(() => {
    getUserData(GET_USER_EXAM_DETAILS + `?exam_id=${examId}`).then((res) => {
      setSubjects(res.data);
    });
  }, []);

  const closeConfirmationModal = () => setConfirmation(false);
  const startExam = () => {
    if (selectedSubject) {
      navigate("/exam/predicted-questions/exam", {
        state: {
          exam: examId === 1 ? "WAEC" : examId === 2 ? "JAMB" : "",
          subject: selectedSubject,
        },
      });
    }
  };

  return (
    <Fragment>
      <Modal
        open={confirmation}
        width={316}
        onClose={closeConfirmationModal}
        onCancel={closeConfirmationModal}
        footer={[
          <Button key="back" onClick={closeConfirmationModal}>
            Back
          </Button>,
          <Button key="submit" type="primary" onClick={startExam}>
            Start Exam
          </Button>,
        ]}
        centered
      >
        <div className="subjectModal">
          <h5>Start Exam</h5>
          <p className="pb-3">
            This exam is predicted by our powerful artificial intelligence
            program.
          </p>
          <p className="pb-3">
            <b>NOTE:</b> These are not 100% correct questions, these are
            possible questions for your next exam
          </p>
        </div>
      </Modal>
      <div className="predicted__questions">
        <div className="predicted__questions__subjects">
          <h3>SELECT SUBJECT</h3>
          <div className="predicted__questions__subjects--list radio-btn-wrap">
            {subjects?.map((i) => (
              <div className="btn-box btn" key={i.exam_subject_id}>
                <input
                  type="radio"
                  id={i.subject_name}
                  name="fav_language"
                  value={i.subject_name}
                  onChange={(e) => setSelectedSubject(e.target.value)}
                />
                <label htmlFor={i.subject_name}>{i.subject_name}</label>
              </div>
            ))}
          </div>
        </div>

        <div className="qucomm-btn-wrap">
          <button
            className="btn size-ms"
            onClick={() => {
              if (selectedSubject) {
                setConfirmation(true);
              }
            }}
          >
            Start Exam
          </button>
        </div>
      </div>
    </Fragment>
  );
};
