import { useEffect } from "react"
import { useState } from "react"
const MathJax = window.MathJax

// import MathJax from "react-mathjax-preview"
const abcd = ["(A)", "(B)", "(C)", "(D)", "(E)"]


const Options = ({ option, index }) => {
    // let q = option?.replace(`xmlns=\\\"http://www.w3.org/1998/Math/MathML\\\"`, ``)
    // q = q?.replace(`xmlns=\\"http://www.w3.org/1998/Math/MathML\\"`, ``)

    const [load, setLoad] = useState(false)

    useEffect(() => {
        setLoad(false)
        scriptLoading()
    }, [option])


    let scriptLoading = () => {
        MathJax?.typesetPromise();
    };
    return (
        <>
            <div className="d-flex">
                {load && index !== undefined && 
                <span className="">
                    {(abcd[index])}
                </span>
                }
                &nbsp;
                {/* <MathJax math={q} onDisplay={() => setLoad(true)} /> */}
                <p>{option}</p>
                {/* {!load && <div className="skeleton-loading" style={{ display: "flex", flexWrap: "wrap", rowGap: "8px", columnGap: "8px" }}>
                    <div style={{ width: "100px" }}>
                        <div style={{ height: "20px", width: "calc(100% - 10px)", maxWidth: "570px" }}></div>
                    </div>
                </div>} */}
            </div>

        </>
    )
}
export default Options
